export const VlotoTaxiButton = ({ onClick, bg = "bg-[#34432D]" }) => {
  return (
    <button
      className={`h-[48px] w-[235px] rounded-[8px] ${bg}  text-[16px] font-semibold leading-[18px] ${
        bg === "bg-white" ? "text-[#34432D]" : "text-white"
      }`}
      onClick={onClick}
    >
      Download de app
    </button>
  );
};
