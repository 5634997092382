import React from "react";

const CarLabel = ({ car, reversed }) => {
  return (
    <div className="absolute border-l-[4px] border-lightGreen pl-6 md:pl-10 w-[400px] lg:w-[490px] h-[111px] flex items-center justify-between md:w-full">
      <div className="flex flex-col md:w-[280px] lg:w-[490px] ">
        <p className="text-white font-bold text-xl md:text-2xl leading-[28px] md:leading-[32px] tracking-[-0.5px] w-full mb-2">
          {car?.name}
        </p>

        <p className="text-[#E8E8E8]">{car?.description}</p>
      </div>

      <img
        src={car.logoImg}
        className="hidden md:block pr-24 lg:pr-12"
        alt={car?.name}
      />
    </div>
  );
};

export default CarLabel;
