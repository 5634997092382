import CloseModal from "../assets/icons/close.svg";
import ModalContent from "../assets/illustrations/modalcontent-new.png";
import WebsiteQR from "../assets/illustrations/qr-website.png";
import Arrow from "../assets/illustrations/modal-arrow.png";
import appStore from "../assets/icons/Appstore.png";
import playStore from "../assets/icons/google-play-badge.png";
import useLockBodyScroll from "../hooks/useLockBodyScroll";
import { ReactComponent as Check } from "../assets/icons/Checkbox.svg";
import { areas } from "../utils/areas";

export const DownloadAppModal = ({ setShowAppModal }) => {
  useLockBodyScroll();
  const totalUsers = areas.reduce((sum, area) => sum + (area.users || 0), 0);

  return (
    <div className=" flex h-[564px] items-center justify-center space-x-10 rounded-[40px] bg-white pr-6 md:w-[744px] md:scale-75 lg:w-[1120px] lg:scale-100 lg:justify-start">
      <img
        src={ModalContent}
        alt="Download de app"
        className="hidden h-full rounded-[40px] lg:block"
      />
      <div className="relative mt-6 ">
        <h1 className="text-[35px]">Download de gratis app</h1>
        <p className="my-6 w-[433px] text-[16px]">
          Bij Vloto rijd je in een 100% elektrische, luxe én goed onderhouden.
          Kies uit de Audi e-tron, Polestar 2, Mercedes EQB of Volkswagen ID4.
        </p>

        <div className="mb-[17px] flex flex-col space-y-3">
          <div className="flex w-full flex-row items-center ">
            <Check />
            <p className="text-[#494949]">Geen verplicht abonnement</p>
          </div>

          <div className="flex w-full flex-row items-center ">
            <Check />
            <p className="text-[#494949]">
              Al meer dan {totalUsers} Vloto gebruikers
            </p>
          </div>
        </div>

        <p className="text-[16px] font-semibold">
          Ontdek Vloto nu zelf! Klik op de onderstaande buttons, download de app
          voor iOS of Android en boek jouw eerste rit gratis{" "}
        </p>

        <div className="flex items-center space-x-4">
          <div className="flex shrink-0 flex-col">
            <a
              href="https://apps.apple.com/us/app/vloto/id1622367728"
              onClick={() => {
                window?.dataLayer?.push({
                  "event": "promotionClick",
                  "gtm.elementUrl":
                    "https://apps.apple.com/us/app/vloto/id1622367728",
                });
              }}
            >
              <img
                src={appStore}
                alt="Apple App store promotion"
                className="mb-2 w-[205px] hover:cursor-pointer sm:mb-4 md:ml-2 lg:ml-0 lg:mb-4"
              />
            </a>

            <a
              href="https://play.google.com/store/apps/details?id=com.vloto.vloto"
              onClick={() => {
                window?.dataLayer?.push({
                  "event": "promotionClick",
                  "gtm.elementUrl":
                    "https://play.google.com/store/apps/details?id=com.vloto.vloto",
                });
              }}
            >
              <img
                src={playStore}
                alt="Google Play store promotion"
                className="mb-2 w-[205px] hover:cursor-pointer sm:mb-8 md:ml-2 lg:ml-0 lg:mb-4"
              />
            </a>
          </div>

          <img src={WebsiteQR} alt="Download de app" />
        </div>
      </div>
      <img
        src={Arrow}
        alt="Pijl dat wijst naar de QR code om de app te downloaden"
        className="absolute bottom-40 right-24 hidden lg:block"
      />
      <img
        src={CloseModal}
        alt="Close modal"
        height={24}
        width={24}
        className="absolute right-8 top-8 hover:cursor-pointer"
        onClick={() => setShowAppModal(false)}
      />
    </div>
  );
};
