import React, { useRef, useState, useEffect, useLayoutEffect } from "react";

export const BoatsHowItWorksUsp = ({
  img,
  alt,
  header,
  title,
  description,
  onClick,
  showLine,
}) => {
  return (
    <div className="flex flex-grow">
      <div className="relative mb-2 mr-[10px] flex h-3 w-3 justify-center self-center overflow-visible rounded-full bg-black lg:mr-[0px] lg:hidden">
        {showLine && (
          <div
            className={`absolute top-[20px] h-[484px] w-[1px] self-center bg-black lg:hidden`}
          ></div>
        )}
      </div>

      <div className=" relative -top-12 left-[150.5px]  hidden h-3 w-3 overflow-visible rounded-full bg-black lg:mr-[0px] lg:flex">
        {showLine && (
          <div
            className={`absolute ml-6 h-[1px] w-[328px] self-center bg-black`}
          ></div>
        )}
      </div>

      <div className="flex flex-col overflow-hidden rounded-[16px] border-[1px] border-[#F4F4F4] pb-10 shadow-card">
        <img src={img} alt={alt} className="w-full" />

        <div className="flex flex-col items-center pt-8">
          <p
            className={`mb-[18px] ${
              title === "Je betaalt alleen voor je daadwerkelijke gebruik."
                ? "w-[209px]"
                : "w-[221px]"
            } text-[20px] font-bold leading-[28px] tracking-[-0.5px] underline-offset-2 `}
          >
            <span
              onClick={onClick ? onClick : undefined}
              className={`text-[#757474] ${
                header === "Download de app" &&
                "cursor-pointer tracking-[-0.5px] underline"
              } decoration-[#757474]`}
            >
              {header}
            </span>{" "}
            {title}
          </p>

          <p className="w-[226px] text-[#757474] ">{description}</p>
        </div>
      </div>
    </div>
  );
};
