import open from "../../assets/icons/open.svg";
import collapsed from "../../assets/icons/collapsed.svg";

const TaxiFaqItem = ({ faq, onClick, bgColor, isOpen = false }) => {
  return (
    <div
      className={`relative w-full rounded-2xl border-[1px] border-[#F4F4F4] bg-white p-5  md:w-[664px]  md:py-[34px] md:px-10 lg:w-[900px]`}
    >
      <div
        onClick={onClick}
        className={`flex justify-between hover:cursor-pointer ${
          isOpen && "mb-3"
        }`}
      >
        <p
          className={`text-black" } min-w-[244px] font-DMSans text-lg font-bold leading-[32px] tracking-[-0.5px]
          md:text-2xl`}
        >
          {faq.question}
        </p>
        <img
          src={isOpen ? open : collapsed}
          alt="open item"
          className="h-[24px] w-[24px]"
        />
      </div>

      {isOpen && (
        <p className="w-full text-lg leading-[24px] text-[#494949]">
          {faq.answer}
        </p>
      )}

      {isOpen && (
        <div className="absolute left-0 top-6 h-10 w-[5px] rounded-r-[5px] bg-lightGreen"></div>
      )}
    </div>
  );
};

export default TaxiFaqItem;
