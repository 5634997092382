import { forwardRef, useContext, useEffect, useRef, useState } from "react";
import { InView } from "react-intersection-observer";
import { useLocation } from "react-router-dom";
import { UserContext } from "../App";
import * as animationData from "../assets/animations/celebrate.json";
import PolestarZeeburgereiland from "../assets/photos/Polestar/polestar-zeeburgereiland.png";
import Polestar from "../assets/photos/cars/polestar-krijgsman.webp";
import Volkswagen from "../assets/photos/cars/volkswagen-krijgsman.png";

import { Element } from "react-scroll";
import ReviewCard from "../components/Review/ReviewCard";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { userFeedback } from "../utils/getRecommendedPlan";
import { areas } from "../utils/areas";

export const ReviewBlock = forwardRef(
  (
    {
      setShowVideoPlayer,
      showAppDownloadModal,
      city,
      showSwirl = false,
      setShowAppModal,
      setCurrentlyInView,
    },
    ref
  ) => {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    const { selectedCity } = useContext(UserContext);
    const location = useLocation();
    const { width } = useWindowDimensions();

    const containerRef = useRef(null);

    const renderImage = () => {
      switch (selectedCity.name) {
        case "De Krijgsman":
          return Polestar;
        case "Zeeburgereiland":
          return PolestarZeeburgereiland;
        default:
          return Volkswagen;
      }
    };

    const [isScrollingLeft, setIsScrollingLeft] = useState(false);

    const totalUsers = areas.reduce((sum, area) => sum + (area.users || 0), 0);

    return (
      <InView
        ref={ref}
        className="relative mt-10 flex w-full flex-col items-center py-[60px]  md:py-[80px] lg:py-[140px]"
        as="div"
        threshold={width < 744 ? 0.3 : 0.4}
        onChange={(inView, entry) =>
          entry.isIntersecting && setCurrentlyInView("Reviews")
        }
      >
        {selectedCity.name !== "Alle" ? (
          <h2 className="mb-[50px]  px-[33px] text-center text-[24px] font-medium leading-[32px] md:px-[53px] lg:mb-[60px] lg:w-[662px] lg:text-[32px] lg:leading-[40px]">
            Je buren zijn al fan. Sluit je aan bij{" "}
            <b className="text-[#1CA556]">{selectedCity.users} andere </b>Vloto
            rijders in {selectedCity.name}
          </h2>
        ) : (
          <h2 className="mb-[50px]  px-[33px] text-center text-[24px] font-medium leading-[32px] md:px-[53px] lg:mb-[60px] lg:w-[662px] lg:text-[32px] lg:leading-[40px]">
            Je buren zijn al fan. Sluit je aan bij{" "}
            <b className="text-[#1CA556]">{totalUsers} andere </b>Vloto rijders.
          </h2>
        )}

        <Element
          ref={containerRef}
          className="grid w-full grid-flow-col grid-rows-1 gap-[18px] overflow-x-auto px-5 scrollbar-hide md:grid-rows-2 lg:gap-6 "
        >
          {userFeedback.map((feedback) => (
            <ReviewCard
              key={feedback.name}
              name={feedback.name}
              feedback={feedback.feedback}
              type={feedback.type}
            />
          ))}
        </Element>
      </InView>
    );
  }
);
