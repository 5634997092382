import React, { useContext } from "react";
import { ReactComponent as Close } from "../assets/icons/close.svg";
import { ReactComponent as Download } from "../assets/icons/download.svg";
import { Button } from "./Button";
import Mobile from "../assets/FirstRideModal/Mobile.png";
import Desktop from "../assets/FirstRideModal/desktop.png";
import WebsiteQR from "../assets/FirstRideModal/qr.png";
import appStore from "../assets/icons/Appstore.png";
import playStore from "../assets/icons/google-play-badge.png";
import useLockBodyScroll from "../hooks/useLockBodyScroll";
import { UserContext } from "../App";

const FirstRideModal = ({ closeModal, showAppModal }) => {
  useLockBodyScroll();
  const { selectedCity } = useContext(UserContext);

  return (
    <div className="bottom-0 z-50 flex w-screen flex-col items-center rounded-t-[24px] bg-white px-6 pt-6 md:h-[752px] md:max-h-[752px] md:w-[611px] md:max-w-[611px] md:overflow-hidden md:rounded-[30px] md:p-0 lg:h-[552px] lg:w-[1120px] lg:max-w-[1120px] lg:pl-[90px]">
      <div
        onClick={closeModal}
        className="absolute top-8 right-8 hidden cursor-pointer lg:block"
      >
        <Close />
      </div>

      <div className="flex max-w-[327px] flex-col md:max-w-none lg:flex-row">
        <div className="mb-10 flex flex-col md:mb-0 lg:mt-20">
          <div className="mb-6 flex items-center justify-between">
            <h5 className="w-[185px] text-left font-DMSans tracking-[-0.5px] md:hidden">
              Je eerste rit is altijd gratis
            </h5>

            <p className="hidden font-DMSans text-[32px] leading-[40px] md:mt-[60px] md:flex lg:mt-0">
              Je eerste rit is altijd <b className="ml-1.5">gratis</b>
            </p>
            <div
              onClick={closeModal}
              className="right-8 cursor-pointer md:absolute md:top-8 lg:hidden"
            >
              <Close />
            </div>
          </div>

          <div className="mb-6 rounded-[14px] border-[1px] border-[#7BE2A6] bg-[#E9FAF0] p-4 md:w-[404px] lg:flex lg:flex-row lg:items-center">
            <div className="mb-3 flex h-[38px] w-fit items-center justify-center rounded-[40px] bg-[#1CA556]">
              <p className=" px-[10px] py-[6px] text-[14px] font-bold leading-[16px] text-white lg:text-[16px]">
                DEAL
              </p>
            </div>

            <p className="w-[272px] lg:ml-5  lg:text-[20px] lg:leading-[32px]">
              Gebruik promotiecode{" "}
              <b className="text-[#157C40]">{selectedCity.coupon}</b> om 1 uur{" "}
              {selectedCity.couponHasKm ? "en 10km " : ""}
              gratis te rijden
            </p>
          </div>

          <p className="mb-6 md:w-[310px] lg:mb-8">
            <b>Om deze coupon te claimen</b>: Download de app, registreer je en
            claim de promocode op je accountpagina in de app
          </p>

          <div className=" hidden items-start lg:flex">
            <p className="w-[124px] text-xl font-semibold lg:text-[16px] lg:leading-[24px]">
              Scan de QR code om de app te downloaden
            </p>

            <div className="ml-6 mr-5 rounded-[16px] bg-white p-2 shadow-md">
              <img src={WebsiteQR} alt="Download de app" className="" />
            </div>

            <div className="items-between flex h-full shrink-0 flex-col justify-between">
              <a
                href="https://apps.apple.com/us/app/vloto/id1622367728"
                onClick={() => {
                  window?.dataLayer?.push({
                    "event": "promotionClick",
                    "gtm.elementUrl":
                      "https://apps.apple.com/us/app/vloto/id1622367728",
                  });
                }}
              >
                <img
                  src={appStore}
                  alt="Apple App store promotion"
                  className="w-[136px] hover:cursor-pointer "
                />
              </a>

              <a
                href="https://play.google.com/store/apps/details?id=com.vloto.vloto"
                onClick={() => {
                  window?.dataLayer?.push({
                    "event": "promotionClick",
                    "gtm.elementUrl":
                      "https://play.google.com/store/apps/details?id=com.vloto.vloto",
                  });
                }}
              >
                <img
                  src={playStore}
                  alt="Google Play store promotion"
                  className="w-[136px] hover:cursor-pointer"
                />
              </a>
            </div>
          </div>

          <div className="w-[200px] space-y-[6px] lg:hidden">
            <Button
              onClick={showAppModal}
              title={"Download de app"}
              variant="solid"
            />

            <div className=" flex items-center space-x-1 md:hidden">
              <Download />
              <p className="">
                <b>3000+</b> Downloads
              </p>
            </div>
          </div>
        </div>

        <img
          src={Mobile}
          alt="Promotiecoupons in de Vloto app"
          className=" hidden md:flex md:w-[429px] lg:hidden"
        />
        <img
          src={Desktop}
          alt="Promotiecoupons in de Vloto app"
          className=" ml-28 hidden h-[552px]  lg:block lg:min-w-[514px]"
        />
      </div>
    </div>
  );
};

export default FirstRideModal;
