import AudiLogo from "../assets/photos/Audi/audi-logo.png";
import AudiEtron from "../assets/photos/Audi/AudiEtron.png";
import AudiEtronCard from "../assets/photos/Audi/AudiEtronCard.png";
import AudiGallery1 from "../assets/photos/Audi/AudiGallery1.png";
import AudiGallery2 from "../assets/photos/Audi/AudiGallery2.png";
import AudiGallery3 from "../assets/photos/Audi/AudiGallery3.png";
import AudiGallery4 from "../assets/photos/Audi/AudiGallery4.png";
import AudiGallery5 from "../assets/photos/Audi/AudiGallery5.png";

import MercedesLogo from "../assets/photos/Mercedes/mercedes-logo.png";
import MercedesEQB from "../assets/photos/Mercedes/MercedesEQB.png";
import MercedesEQBCard from "../assets/photos/Mercedes/MercedesEQBCard.png";
import MercedesGallery1 from "../assets/photos/Mercedes/MercedesGallery1.png";
import MercedesGallery2 from "../assets/photos/Mercedes/MercedesGallery2.png";
import MercedesGallery3 from "../assets/photos/Mercedes/MercedesGallery3.png";
import MercedesGallery4 from "../assets/photos/Mercedes/MercedesGallery4.png";

import PolestarLogo from "../assets/photos/Polestar/polestar-logo.png";
import Polestar2 from "../assets/photos/Polestar/Polestar2.png";
import Polestar2Card from "../assets/photos/Polestar/Polestar2Card.png";
import PolestarGallery1 from "../assets/photos/Polestar/PolestarGallery1.png";
import PolestarGallery2 from "../assets/photos/Polestar/PolestarGallery2.png";
import PolestarGallery3 from "../assets/photos/Polestar/PolestarGallery3.png";
import PolestarGallery4 from "../assets/photos/Polestar/PolestarGallery4.png";
import PolestarGallery5 from "../assets/photos/Polestar/PolestarGallery5.png";
import PolestarGallery6 from "../assets/photos/Polestar/PolestarGallery6.png";

import VolkswagenLogo from "../assets/photos/Volkswagen/volkswagen-logo.png";
import VolkswagenID4 from "../assets/photos/Volkswagen/VolkswagenID4.png";
import VolkswagenID4Card from "../assets/photos/Volkswagen/VolkswagenID4Card.png";
import VWID4Gallery1 from "../assets/photos/Volkswagen/VWID4Gallery1.png";
import VWID4Gallery2 from "../assets/photos/Volkswagen/VWID4Gallery2.png";
import VWID4Gallery3 from "../assets/photos/Volkswagen/VWID4Gallery3.png";
import VWID4Gallery4 from "../assets/photos/Volkswagen/VWID4Gallery4.png";
import VWID4Gallery5 from "../assets/photos/Volkswagen/VWID4Gallery5.png";

export const cars = [
  {
    id: 1,
    name: "Audi e-tron 55 Quattro",
    description: `Een bereik van 450 kilometer.`,
    logoImg: AudiLogo,
    cardImg: AudiEtronCard,
    img: AudiEtron,
    galleryImages: [
      AudiGallery1,
      AudiGallery2,
      AudiGallery3,
      AudiGallery4,
      AudiGallery5,
    ],
  },
  {
    id: 2,
    name: "Mercedes EQB",
    description: "Een bereik van 450 kilometer.",
    logoImg: MercedesLogo,
    cardImg: MercedesEQBCard,
    img: MercedesEQB,
    galleryImages: [
      MercedesGallery1,
      MercedesGallery2,
      MercedesGallery3,
      MercedesGallery4,
    ],
  },
  {
    id: 3,
    name: "Polestar 2",
    description: `Een bereik van 400 kilometer.`,
    logoImg: PolestarLogo,
    cardImg: Polestar2Card,
    img: Polestar2,
    galleryImages: [
      PolestarGallery1,
      PolestarGallery2,
      PolestarGallery3,
      PolestarGallery4,
      PolestarGallery5,
      PolestarGallery6,
    ],
  },
  {
    id: 4,
    name: "Volkswagen ID.4",
    description: `Een bereik van 450 kilometer.`,
    logoImg: VolkswagenLogo,
    cardImg: VolkswagenID4Card,
    img: VolkswagenID4,
    galleryImages: [
      VWID4Gallery1,
      VWID4Gallery2,
      VWID4Gallery3,
      VWID4Gallery4,
      VWID4Gallery5,
    ],
  },
];
