import React, { useEffect } from "react";
import { isAndroid, isIOS, isMobile } from "react-device-detect";
import ScrollLayout from "../../layouts/ScrollLayout";
import { AppUseBlock } from "../../blocks/AppUseBlock";

const TaxiAppPage = () => {
  const redirectToStore = () => {
    if (isAndroid) {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.vloto.vlototaxi";
    } else if (isIOS) {
      window.location.href = "https://apps.apple.com/us/app/vloto/id6476565206";
    }
  };

  useEffect(() => {
    if (isMobile) {
      redirectToStore();
    }
  }, []);

  return (
    <ScrollLayout showFooter={true}>
      <div className="h-screen"></div>
    </ScrollLayout>
  );
};

export default TaxiAppPage;
