import React from "react";
import NoDeposit from "../../assets/boats/Illustrations/no-deposits.png";

export const BoatsNoDeposits = ({ setShowAppModal }) => {
  return (
    <div className="flex flex-col px-5 py-[80px] lg:py-[110px]">
      <div className="self-center lg:flex lg:max-w-[1440px] lg:flex-row lg:items-center lg:space-x-[144px]">
        <div className="md:mb-10 md:h-[466px] md:w-[510px]">
          <img src={NoDeposit} alt="Geen borg nodig" className="mb-6 w-full" />
        </div>

        <div className="flex flex-col">
          <h4 className="mb-3 w-[80%] font-DMSans text-2xl  font-bold tracking-[-0.5px] md:mb-6 md:w-[356px] md:text-32 md:leading-[40px] lg:mb-8 ">
            Een borg is niet nodig
          </h4>

          <p className=" lg: mb-8 text-[#494949] md:mb-10 md:w-[344px] lg:mb-[50px]">
            Je checkt zelf in en uit met de boten. Volg de stappen in de app om
            de boot te starten en te stoppen
          </p>

          <button
            className="h-[48px] w-[166px] rounded-[8px] bg-vlotoGreen font-semibold text-white"
            onClick={setShowAppModal}
          >
            Download de app
          </button>
        </div>
      </div>
    </div>
  );
};
