export const USPCardLarge = ({ image, title, description, comingSoon }) => {
  return (
    <div
      className={`flex flex-col items-center overflow-hidden rounded-[20px] border-[1px] border-[#F4F4F4] bg-white pb-[32px] md:h-[460px] md:min-w-[260px] md:rounded-[30px] lg:h-[480px] lg:max-h-[480px] lg:w-[361px] lg:pb-[60px] lg:first:col-span-2 lg:first:w-[735px]  ${
        title === "Vloto voor het hele gezin"
          ? "lg:max-w-[396px]"
          : "lg:first:max-w-[735px]"
      }  `}
    >
      <img
        alt={title}
        src={image}
        className="mb-[23px] min-w-[440px] md:h-[200px] md:min-w-[300px] md:flex-grow lg:mb-6 lg:min-w-[400px] lg:object-none "
      />

      <div className="flex flex-col items-center">
        {/* {comingSoon && (
          <div className="bg-[#D2D2D2] px-[10px] py-[2px] rounded-[22px] mb-4 -mt-6">
            <p className="text-white text-sm leading-[22px] font-bold">
              BINNENKORT BESCHIKBAAR
            </p>
          </div>
        )} */}

        <p className="mb-2 font-DMSans text-xl font-bold leading-[28px] tracking-[-0.5px] lg:mb-3 lg:text-2xl lg:leading-[32px]">
          {title}
        </p>

        <p
          className={`px-7 text-center text-[#757474] ${
            title === "Vloto voor het hele gezin"
              ? "max-w-[332px] lg:w-[300px] lg:max-w-[1000px]"
              : "max-w-[400px] lg:w-[480px] lg:max-w-[1000px]"
          }`}
        >
          {description}
        </p>
      </div>
    </div>
  );
};
