import React, { forwardRef, useEffect, useState } from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { InView } from "react-intersection-observer";

export const BoatsPriceCalculation = forwardRef(
  ({ setShowAppModal, setCurrentlyInView }, ref) => {
    const [sliderValue, setSliderValue] = useState(120);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [price, setPrice] = useState(0);
    const pricePerMinute = 0.58333333;

    const formatTime = (totalMinutes) => {
      const hours = Math.floor(totalMinutes / 60);
      const mins = totalMinutes % 60;

      setHours(hours < 10 ? "0" + hours : hours);
      setMinutes(mins < 10 ? "0" + mins : mins);

      setPrice(
        (totalMinutes * pricePerMinute).toLocaleString("nl-NL", {
          style: "currency",
          currency: "EUR",
        })
      );
    };

    useEffect(() => {
      formatTime(sliderValue);
    }, [sliderValue]);

    return (
      <InView
        ref={ref}
        as="div"
        threshold={0.8}
        onChange={(inView) => inView && setCurrentlyInView("Tarieven")}
      >
        <div className="flex flex-col px-5 py-[80px] lg:py-[110px]">
          <div className="flex flex-col self-center lg:max-w-[1440px] lg:flex-row-reverse lg:items-center  lg:space-x-[197px] lg:space-x-reverse">
            <div className="mb-6 w-full self-center rounded-[16px] bg-vlotoGreen px-5 pt-[15px] pb-[30px]  md:mb-10 md:w-[510px] md:px-[60px] md:pt-11 md:pb-[60px] lg:mb-0">
              <p className="mb-6 font-DMSans text-[56px] font-bold leading-[64px] text-white md:text-[72px] md:leading-[84px]">
                {price}
              </p>

              {/* <p className="mb-6 text-[#AEB4AB]">
                Calculated based on{" "}
                {pricePerMinute.toLocaleString("nl-NL", {
                  style: "currency",
                  currency: "EUR",
                })}
                /min.
              </p> */}

              <div className="mb-5 flex items-center space-x-4 md:justify-between">
                <p className="text-white md:w-[196px] md:text-[20px] md:font-bold md:leading-[28px]">
                  Hoe lang wil je de boot boeken?
                </p>

                <div>
                  <div className="rounded-[8px] bg-[#5D6957] px-4 py-2 md:py-[10px]">
                    <p className=" flex space-x-2 leading-[22px] text-[#D6D9D5]">
                      <span className="font-bold text-white">{hours} </span> uur
                      <span className="font-bold text-white">
                        {minutes}{" "}
                      </span>{" "}
                      min.
                    </p>
                  </div>
                </div>
              </div>

              <Slider
                value={sliderValue}
                onChange={(e) => setSliderValue(e)}
                min={0}
                max={480}
                step={5}
                handleStyle={{
                  backgroundColor: "#23CE6B",
                  borderColor: "#FAFAFA",
                  opacity: 1,
                  boxShadow: "0px 0px 2px 1px rgba(0, 0, 0, 0.2)",
                  borderRadius: 20,
                }}
                railStyle={{ backgroundColor: "#858E81", borderRadius: 20 }}
                trackStyle={{
                  backgroundColor: "#23CE6B",
                  borderRadius: 20,
                }}
              />
            </div>

            <div className="">
              <h4 className="mb-3 w-[80%] font-DMSans text-2xl font-bold tracking-[-0.5px] md:mb-8 md:w-[350px] md:text-32 md:leading-[40px] lg:w-[309px] ">
                Vaar de Vloto sloep voor €35 per uur{" "}
              </h4>

              <p className="mb-8 text-[#494949] md:mb-[50px] md:w-[382px] lg:w-[288px]">
                Je kan ook een half uur boeken, of zelfs maar 10 minuten. Je
                betaalt alleen voor het daadwerkelijke gebruik
              </p>

              <button
                className="h-[48px] w-[166px] rounded-[8px] bg-vlotoGreen font-semibold text-white"
                onClick={setShowAppModal}
              >
                Download de app
              </button>
            </div>
          </div>
        </div>
      </InView>
    );
  }
);
