import { USPCard } from "../components/USPCard";
import USPSubscription from "../assets/illustrations/USPCards/Subscription-usp.png";
import USPBenefits from "../assets/illustrations/USPCards/Benefits-usp.png";
import USPFamily from "../assets/illustrations/USPCards/Family-usp-share.png";
import USPElectric from "../assets/illustrations/USPCards/Electric-usp.png";
import USPCharging from "../assets/illustrations/USPCards/Charging-usp.png";
import USPParking from "../assets/illustrations/USPCards/Parking-usp.png";
import Amsterdam from "../assets/illustrations/USPCards/Amsterdam.png";
import CleanCar from "../assets/illustrations/USPCards/clean-car.png";

import useWindowDimensions from "../hooks/useWindowDimensions";
import { forwardRef } from "react";
import { InView } from "react-intersection-observer";
export const USPBlock = forwardRef(({ city, setCurrentlyInView }, ref) => {
  const { width } = useWindowDimensions();

  return (
    <InView
      ref={ref}
      className=" w-full py-[80px]  lg:flex  lg:max-w-[1120px] lg:flex-col lg:items-center lg:py-[140px] xl:max-w-[1120px]"
      as="div"
      threshold={0.4}
      onChange={(inView, entry) =>
        entry.isIntersecting && setCurrentlyInView("Voordelen")
      }
    >
      <p className="mb-10 px-5 text-center font-DMSans text-2xl font-medium leading-['32px'] tracking-[-0.5px] text-[#1C1C1C] md:min-w-[645px] md:px-[100px] lg:mb-20 lg:w-[645px] lg:px-0 lg:text-32 lg:leading-[40px] ">
        Geniet van duurzaam en luxe vervoer,{" "}
        <b className="text-[#1CA556]">geheel zorgvrij</b>. Wij nemen alles uit
        handen, van onderhoud tot verzekering.
      </p>

      <div className=" grid w-full grid-flow-col grid-rows-1  gap-3 overflow-x-auto px-5 scrollbar-hide lg:grid-flow-row lg:grid-cols-3 lg:gap-6">
        <USPCard
          reversed={false}
          image={Amsterdam}
          title={"Gratis parkeren in heel Amsterdam"}
          description={
            "Bespaar €7,50 per uur, parkeer gratis door heel Amsterdam"
          }
        />

        <USPCard
          reversed={false}
          image={USPSubscription}
          title={"Scherp geprijsd."}
          description={
            "Geen vaste abonnementen en geheel afgestemd op jouw gebruik."
          }
        />

        <USPCard
          image={USPParking}
          title={"Altijd dichtbij."}
          description={
            "De auto staat altijd om de hoek, of zelfs voor je deur!"
          }
        />

        <USPCard
          image={USPBenefits}
          title={"Bij Vloto is alles inbegrepen,"}
          description={
            "van verzekering tot brandstof en van onderhoud tot schoonmaak."
          }
        />

        <USPCard
          image={USPElectric}
          title={"Al onze auto's zijn 100% elektrisch."}
          description={`Schoon en stil vervoer in ${city}.`}
        />
        <USPCard
          image={USPFamily}
          title={"Deel Vloto met je huishouden."}
          description={
            "Iedereen rijdt op hetzelfde account, zo betaal je nooit teveel!"
          }
        />

        <USPCard
          reversed={true}
          image={USPCharging}
          title={"In elke auto vind je een laadpas,"}
          description={"waarmee je overal kan laden."}
        />

        <USPCard
          image={CleanCar}
          title={"Altijd een brandschone auto,"}
          description={"dagelijkse schoonmaak en verantwoordelijke gebruikers."}
        />
      </div>
    </InView>
  );
});
