import MapBackground from "../assets/illustrations/Map-background.png";
import Ellipse from "../assets/illustrations/USPCards/Ellipse.png";
import EllipseLarge from "../assets/illustrations/USPCards/Ellipse-Large.png";
import QR from "../assets/illustrations/qr.svg";
import Apple from "../assets/logo/apple.svg";
import Playstore from "../assets/logo/playstore.svg";
import DealLabel from "./DealLabel";

export const AppUSPCardLarge = ({ image }) => {
  const goToAppstore = (platform) => {
    if (platform === "GPlay") {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.vloto.vloto";
    } else {
      window.location.href = "https://apps.apple.com/us/app/vloto/id1622367728";
    }
  };

  return (
    <div className="relative flex flex-row overflow-hidden rounded-[16px] bg-vlotoGreen md:h-[360px] md:w-[664px] md:rounded-[30px] lg:h-[480px] lg:w-[1120px] lg:rounded-[30px]">
      <div className="absolute flex lg:bottom-0 ">
        <div className="absolute bottom-0 left-[0px] flex flex-col">
          <img
            src={image}
            className="z-20 rounded-[16px] md:ml-[60px] md:w-[196px] lg:ml-[100px] lg:w-[300px]"
            alt="Telefoon"
          />
        </div>

        <img
          src={MapBackground}
          className="rounded-[16px] lg:max-h-[480px]"
          alt="Kaart"
        />
      </div>

      <div className=" z-20 ml-[298px] text-white md:ml-[298px] lg:ml-[450px] ">
        <div className=" mt-[57px] lg:mt-[85px]">
          <DealLabel />
          <h5 className=" mt-4 mb-3 text-start text-2xl leading-[40px]  lg:text-[32px]">
            Rij vandaag nog met Vloto
          </h5>
        </div>

        <p className="mb-10 leading-[24px] text-[#D6D9D5] md:w-[291px] lg:mb-[50px] lg:w-[492px] lg:text-lg lg:leading-[28px]">
          Download de app in de Apple App store of Google Play en stap vandaag
          nog in een van onze premium deelauto's.
        </p>

        <button className="rounded-lg bg-[#23CE6B] px-[24px] py-[15px] text-lg leading-[18px] text-black lg:hidden ">
          Download de app
        </button>

        <div className="hidden items-center lg:flex lg:space-x-10">
          <img src={QR} className="rounded-[16px] bg-white p-3" alt="QR code" />
          <div>
            <p className="mb-[24px] text-white lg:w-[298px]">
              Scan de QR-code met de camera van je telefoon om de app te
              downloaden
            </p>

            <div className="flex space-x-10">
              <button
                className="flex items-center space-x-3"
                onClick={() => goToAppstore("Apple")}
              >
                <img src={Apple} alt="Apple App Store" />
                <p className="text-lg leading-[28px] text-white">App store</p>
              </button>

              <button
                onClick={() => goToAppstore("GPlay")}
                className="flex items-center space-x-3"
              >
                <img src={Playstore} alt="Google Play Store" />
                <p className="text-lg leading-[28px] text-white">Google Play</p>
              </button>
            </div>
          </div>
        </div>
      </div>

      <img
        src={Ellipse}
        className=" absolute bottom-0 right-0 lg:hidden"
        alt="Effect"
      />

      <img
        src={EllipseLarge}
        className="absolute bottom-0 right-0 hidden lg:block"
        alt="Effect"
      />
    </div>
  );
};
