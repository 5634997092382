import { LazyLoadComponent } from "react-lazy-load-image-component";

export const USPCard = ({ image, title, description, reversed }) => {
  return (
    <div className="w-[316px] min-w-[316px] flex-shrink-0 overflow-hidden rounded-[16px] border-[1px] border-[#F4F4F4] bg-white pb-[40px] md:flex md:w-full md:flex-col md:items-center md:rounded-[30px] ">
      <img src={image} alt="USP" className="mb-3 md:flex md:w-full" />
      <div className="px-[27px] lg:pl-10 lg:pr-[57px]">
        <span
          className={` leading-[24px] ${
            reversed ? "font-medium text-[#757474]" : "font-bold text-[#1C1C1C]"
          }`}
        >
          {title}{" "}
          <span
            className={` leading-[24px] ${
              reversed
                ? "font-bold text-[#1C1C1C]"
                : "font-medium text-[#757474]"
            }`}
          >
            {description}
          </span>
        </span>
      </div>
    </div>
  );
};
