import { useContext, useEffect, useRef, useState } from "react";
import { isAndroid, isIOS, isMobile } from "react-device-detect";
import ReactGA from "react-ga";
import ReactPlayer from "react-player/lazy";
import { useNavigate } from "react-router-dom";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { AppUseBlock } from "./blocks/AppUseBlock";
import { FaqBlock } from "./blocks/FaqBlock";
import { HeroBlock } from "./blocks/HeroBlock";
import { CarBlock } from "./blocks/CarBlock";
import { SubscriptionCalculationBlock } from "./blocks/SubscriptionCalculationBlock";
import { USPBlock } from "./blocks/USPBlock";
import { DownloadAppModal } from "./components/DownloadAppModal";
import ScrollToTop from "./components/ScrollToTop";
import useWindowDimensions from "./hooks/useWindowDimensions";

import { UserContext } from "./App";
import { NewUspBlock } from "./blocks/NewUspBlock";
import { ReviewBlock } from "./blocks/ReviewBlock";
import { HowItWorksBlock } from "./blocks/HowItWorksBlock";
import { BoatFooter } from "./components/BoatFooter";
import { Header } from "./components/NewHeader";
import { ExclusiveBanner } from "./components/Banners/ExclusiveBanner";
import ComparisonTable from "./components/ComparisonTable";

const HomePage = ({ city }) => {
  const { selectedCity } = useContext(UserContext);
  const { width } = useWindowDimensions();

  const [locationState, setLocationState] = useState(null);
  const [isHeaderOpen, setIsHeaderOpen] = useState(false);
  const [currentlyInView, setCurrentlyInView] = useState("Home");
  const [showHeaderSectionBorder, setShowHeaderSectionBorder] = useState(false);

  const TRACKING_ID = "G-8GVPPG2RFP";

  ReactGA.initialize(TRACKING_ID);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const showAppDownloadModal = () => {
    if (!isMobile) {
      setShowAppModal(true);
      return;
    }

    if (isAndroid) {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.vloto.vloto";
    } else if (isIOS) {
      window.location.href = "https://apps.apple.com/us/app/vloto/id1622367728";
    }
  };

  const [showVideoPlayer, setShowVideoPlayer] = useState(false);
  const [showAppModal, setShowAppModal] = useState(false);
  const [headerBackgroundColor, setHeaderBackgroundColor] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const navigate = useNavigate();

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (scrollPosition > 40) {
      setHeaderBackgroundColor(true);
      setShowHeaderSectionBorder(true);
    } else {
      setHeaderBackgroundColor(false);
      setShowHeaderSectionBorder(false);
    }
  }, [scrollPosition]);

  const homeRef = useRef(null);
  const benefitsRef = useRef(null);
  const ourCarsRef = useRef(null);
  const ourAppRef = useRef(null);
  const reviewRef = useRef(null);
  const priceRef = useRef(null);
  const communitiesRef = useRef(null);
  const howItWorksRef = useRef(null);

  return (
    <div className="flex flex-col bg-[#FAFAFA]">
      <ScrollToTop />

      <Header
        showAppDownloadModal={showAppDownloadModal}
        isHeaderOpen={isHeaderOpen}
        setIsHeaderOpen={setIsHeaderOpen}
        currentlyInView={currentlyInView}
        showHeaderSectionBorder={showHeaderSectionBorder}
        headerBackgroundColor={headerBackgroundColor}
        homeRef={homeRef}
        benefitsRef={benefitsRef}
        ourCarsRef={ourCarsRef}
        ourAppRef={ourAppRef}
        priceRef={priceRef}
        communitiesRef={communitiesRef}
        howItWorksRef={howItWorksRef}
        reviewRef={reviewRef}
      />
      <div
        onTouchStart={() => setIsHeaderOpen(false)}
        onClick={() => setIsHeaderOpen(false)}
        className={`z-40 ${
          isHeaderOpen &&
          "bg-white opacity-10 transition-opacity duration-200 ease-in-out"
        } `}
      >
        <div className="flex flex-col items-center bg-[#FAFAFA]">
          {showVideoPlayer && (
            <Popup
              contentStyle={{
                backgroundColor: "transparent",
                border: "none",
                width: "90%",
              }}
              lockScroll={true}
              open={showVideoPlayer}
              onClose={() => setShowVideoPlayer(false)}
            >
              <div className="h-80 w-full items-center justify-center px-2 md:h-[500px] lg:h-[700px] xl:h-[700px]">
                <ReactPlayer
                  url="https://youtu.be/8vsVJRCq_sM"
                  width={"100%"}
                  height={"100%"}
                  muted={false}
                  volume={1}
                  controls={false}
                  style={{
                    borderRadius: 15,
                    overflow: "hidden",
                  }}
                />
              </div>
            </Popup>
          )}

          {showAppModal && (
            <Popup
              contentStyle={{
                backgroundColor: "transparent",
                border: "none",
                width: width > 1260 ? 1120 : 744,
              }}
              open={showAppModal}
              onClose={() => setShowAppModal(false)}
            >
              <DownloadAppModal setShowAppModal={setShowAppModal} />
            </Popup>
          )}

          <HeroBlock
            ref={homeRef}
            setCurrentlyInView={setCurrentlyInView}
            city={city}
            showSwirl={selectedCity.name === "De Krijgsman"}
            showAppDownloadModal={showAppDownloadModal}
            showVideoPlayer={showVideoPlayer}
            setShowVideoPlayer={setShowVideoPlayer}
          />

          <USPBlock
            city={selectedCity.name}
            ref={benefitsRef}
            setCurrentlyInView={setCurrentlyInView}
          />

          <NewUspBlock
            showAppDownloadModal={showAppDownloadModal}
            setCurrentlyInView={setCurrentlyInView}
          />

          {selectedCity.name === "Alle" && (
            <div className="px-5 md:w-full md:max-w-[744px] md:px-0 lg:max-w-[1120px]">
              <ExclusiveBanner showModal={showAppDownloadModal} />
            </div>
          )}

          <ReviewBlock
            ref={reviewRef}
            setCurrentlyInView={setCurrentlyInView}
          />

          <CarBlock ref={ourCarsRef} setCurrentlyInView={setCurrentlyInView} />

          <SubscriptionCalculationBlock
            setCurrentlyInView={setCurrentlyInView}
            city={city}
            ref={priceRef}
            showAppDownloadModal={showAppDownloadModal}
          />

          <div className="-mt-10 flex w-full items-center justify-center px-5 lg:-mt-28 lg:w-[1120px] lg:px-0">
            <ComparisonTable />
          </div>

          <AppUseBlock
            setCurrentlyInView={setCurrentlyInView}
            showAppDownloadModal={showAppDownloadModal}
            ref={ourAppRef}
          />

          <FaqBlock />

          {/* {
            city === "De Krijgsman" && (
              <>
                <div className="self-center py-[70px] lg:max-w-[1260px] lg:py-[120px]">
                  <p className="mb-10 w-[226px] font-DMSans text-2xl font-medium tracking-[-0.5px] md:w-full">
                    We hebben iets nieuws voor je...
                  </p>

                  <div className="mb-4 grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-5 ">
                    <div className="flex w-full flex-col items-center self-center rounded-[16px] border-[1px] border-card bg-white py-10 md:h-full md:py-11 lg:max-w-[548px] lg:pb-[70px] lg:pt-[60px]">
                      <img
                        src={BoatImage}
                        alt="Vloto boot"
                        className="mb-5 h-[147px] w-[260px] self-center lg:h-[248px] lg:w-[438px]"
                      />
                      <p className="mb-8 w-[246px] self-center text-center font-DMSans text-2xl font-medium tracking-[-0.5px] lg:w-[369px] lg:text-32 lg:leading-[40px]">
                        Vloto boten zijn nu live in De Krijgsman
                      </p>

                      <div className="">
                        <Button
                          variant={"solid"}
                          title="Lees meer"
                          onClick={() => navigate("/boten")}
                        />
                      </div>
                    </div>
                    <IjburgBanner />
                  </div>
                </div>
              </>
            )
            // : (
            //   <div className="flex flex-col items-center justify-center self-center  py-[80px] md:py-[80px] lg:py-[140px]">
            //     <h5 className="mb-10 md:max-w-[480px] lg:mb-20 lg:max-w-[804px] lg:text-[46px] lg:leading-[60px] ">
            //       De auto's worden alleen gedeeld met bewoners van dezelfde
            //       community
            //     </h5>

            //     <div className="relative flex w-full flex-col items-center justify-center overflow-hidden rounded-[16px] pt-8  pb-10 md:w-[663px] md:rounded-[30px] lg:w-[1120px] lg:pt-[60px] lg:pb-[80px]">
            //       <img
            //         src={
            //           selectedCity.name === "IJburg" ? IJburgMobile : LoenenMobile
            //         }
            //         alt={selectedCity.name}
            //         className="absolute w-full rounded-[16px] md:hidden"
            //       />
            //       <img
            //         src={
            //           selectedCity.name === "IJburg" ? IJburgTablet : LoenenTablet
            //         }
            //         alt={selectedCity.name}
            //         className="absolute hidden w-full rounded-[30px] md:block "
            //       />
            //       <img
            //         src={
            //           selectedCity.name === "IJburg"
            //             ? IJburgDesktop
            //             : LoenenDesktop
            //         }
            //         alt={selectedCity.name}
            //         className="absolute top-[0.5px] hidden w-full rounded-[30px] lg:block"
            //       />

            //       <h5 className="z-20 mb-5 w-[236px] text-white md:w-[506px] md:self-start md:pl-10 md:text-start lg:mb-8 lg:pl-[70px] lg:text-[32px] lg:leading-[40px]">
            //         Een deelauto, maar alleen gedeeld met de buren
            //       </h5>

            //       <div className=" z-20 mb-10 space-y-[10px] self-start px-[28px] leading-[24px] text-white lg:px-[70px] lg:text-[20px] lg:leading-[32px]">
            //         <div className="flex space-x-3">
            //           <div className="h-6 w-6">
            //             <Check />
            //           </div>
            //           <p className="">
            //             De mooiste deelauto's tegen de laagste prijs
            //           </p>
            //         </div>

            //         <div className="flex space-x-3">
            //           <div className="h-6 w-6">
            //             <Check />
            //           </div>{" "}
            //           <p className="">
            //             Gedeeld binnen jouw omgeving, zo weet je met wie je deelt
            //           </p>
            //         </div>

            //         <div className="flex space-x-3">
            //           <div className="h-6 w-6">
            //             <Check />
            //           </div>{" "}
            //           <p className="">Schone auto's, geen reclame</p>
            //         </div>

            //         <div className="flex space-x-3">
            //           <div className="h-6 w-6">
            //             <Check />
            //           </div>{" "}
            //           <p className="">
            //             Alleen voor bewoners{" "}
            //             {selectedCity.name === "IJburg"
            //               ? "Haveneiland Oost"
            //               : "Loenen a/d Vecht"}{" "}
            //           </p>
            //         </div>
            //       </div>

            //       <button
            //         onClick={showAppDownloadModal}
            //         className="z-20 rounded-[8px] bg-white py-[15px] px-[34.5px] font-semibold leading-[18px] text-[#34432D] md:ml-10 md:self-start lg:ml-[70px]"
            //       >
            //         Download de app
            //       </button>
            //     </div>
            //   </div>
            // )}
          }
        

          <CarBlock ref={ourCarsRef} setCurrentlyInView={setCurrentlyInView} />

        

          <SubscriptionCalculationBlock
            setCurrentlyInView={setCurrentlyInView}
            city={city}
            ref={priceRef}
            showAppDownloadModal={showAppDownloadModal}
          />

          {selectedCity.name === "IJburg" ||
            (selectedCity.name === "De Krijgsman" && (
              <CommunityBlock
                city={city}
                ref={communitiesRef}
                setCurrentlyInView={setCurrentlyInView}
              />
            ))}
            
            <FuturePlansBlock />
            
          <FaqBlock /> */}
        </div>

        <BoatFooter setShowAppModal={showAppDownloadModal} city={city} />
      </div>
    </div>
  );
};

export default HomePage;
