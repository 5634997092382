import React from "react";
import { ReactComponent as Check } from "../../assets/icons/green-check.svg";

const HeroUsp = ({ text }) => {
  return (
    <div className="flex space-x-[6px]">
      <Check />

      <p>{text}</p>
    </div>
  );
};

export default HeroUsp;
