import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import Slide1 from "../../assets/taxi/Slider/slide-1.png";
import Slide2 from "../../assets/taxi/Slider/slide-2.png";
import Slide3 from "../../assets/taxi/Slider/slide-3.png";
import Slide4 from "../../assets/taxi/Slider/slide-4.png";
import { isMobile } from "react-device-detect";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "../../styles/taxistyles.css";
export const TaxiImageSlider = () => {
  const images = [
    {
      id: 1,
      image: Slide1,
    },

    {
      id: 2,
      image: Slide2,
    },
    {
      id: 3,
      image: Slide3,
    },
    {
      id: 4,
      image: Slide4,
    },
  ];
  return (
    <div className="flex w-full flex-col justify-center   px-5 py-[80px] md:max-w-[744px] md:items-center md:self-center lg:max-w-[1260px] lg:items-center lg:px-[110px]">
      <div className="flex w-full flex-col items-center justify-center self-center ">
        <h1 className="mb-[60px] flex w-[350px] self-start font-DMSans text-xl tracking-[-0.5px]  lg:w-[454px] lg:text-[32px] lg:leading-[40px]">
          Rijd in onze 100% elektrische, luxe Volkswagen ID.4 & 5
        </h1>

        <Swiper
          lazy={true}
          style={{
            borderRadius: isMobile ? 10 : 20,
            "--swiper-navigation-size": "16px",
          }}
          slideToClickedSlide={true}
          className={" h-full w-full lg:h-full"}
          slidesPerView={1}
          modules={[Navigation]}
          pagination={{
            clickable: true,
          }}
          navigation={true}
        >
          {images.map((image, index) => (
            <SwiperSlide key={index} style={{ borderRadius: 30 }}>
              <img
                src={image.image}
                alt={`Foto ${image.id} van de slider`}
                className={`mb-2 h-full w-full rounded-t-[10px] rounded-b-[10px] lg:h-full`}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};
