import React, { forwardRef } from "react";
import { InView } from "react-intersection-observer";
import LuxuryBoatMobile from "../../assets/boats/Illustrations/luxury-boat.png";
import LuxuryBoat from "../../assets/boats/Illustrations/luxury-boat-2.png";

export const BoatsLuxury = forwardRef(({ setCurrentlyInView }, ref) => {
  return (
    <InView
      ref={ref}
      as="div"
      threshold={0.8}
      onChange={(inView) => inView && setCurrentlyInView("Onze boot")}
    >
      <div className="flex flex-col px-5 py-[80px] lg:py-[110px]">
        <div className="self-center lg:max-w-[1440px]">
          <h4 className="mb-6 w-[90%] font-DMSans text-2xl font-bold tracking-[-0.5px] md:w-[380px] md:text-32 md:leading-[40px] lg:mb-[60px] lg:w-[427px]">
            Een stevige, elektrische sloep waar je met tot 8 personen mee kan
            varen
          </h4>

          <img
            src={LuxuryBoatMobile}
            alt="Luxe boten van Vloto"
            className="h-[298px] w-full rounded-[20px] md:hidden"
          />
          <div className="hidden md:flex md:h-[262px] md:w-[510px] lg:h-[480px] lg:w-[932px]">
            <img src={LuxuryBoat} alt="Luxe boten van Vloto" />
          </div>
        </div>
      </div>
    </InView>
  );
});
