import React, { forwardRef } from "react";
import DownloadApp from "../../assets/taxi/book-a-taxi.png";
import InterviewImage from "../../assets/taxi/interview.png";
import PayTheTaxi from "../../assets/taxi/pay-for-the-taxi.png";
import { TaxiHowItWorksUsp } from "./TaxiHowItWorksUsp";
import { InView } from "react-intersection-observer";
import useWindowDimensions from "../../hooks/useWindowDimensions";

export const TaxiHowItWorks = forwardRef(
  ({ setShowAppModal, setCurrentlyInView }, ref) => {
    const { width } = useWindowDimensions();
    return (
      <InView
        ref={ref}
        as="div"
        threshold={width < 1024 ? 0.2 : 0.4}
        onChange={(inView, entry) =>
          entry.isIntersecting && setCurrentlyInView("Hoe werkt het")
        }
      >
        <div className="flex flex-col items-center py-[80px] px-5 lg:py-[110px]">
          <h4 className="mb-8 font-DMSans text-2xl font-bold tracking-[-0.5px] md:mb-[50px] lg:mb-[132px]">
            Hoe het werkt
          </h4>

          <div className=" grid grid-cols-1 gap-10 lg:grid-cols-3">
            <TaxiHowItWorksUsp
              img={DownloadApp}
              alt="Download de app"
              header="Download de app "
              title="en registreer je bij VlotoTaxi."
              description="Houd je rijbewijs, P-nummer en gegevens bij de hand."
              // onClick={setShowAppModal}
              showLine={true}
            />

            <TaxiHowItWorksUsp
              img={InterviewImage}
              alt="Kennismakingsgesprek bij ons op kantoor"
              header="Een kort kennismakingsgesprek"
              title="bij ons op kantoor"
              description={
                "Ons kantoor is in Muiden, we willen je graag een keer ontmoeten en alles uitleggen!"
              }
              showLine={true}
            />

            <TaxiHowItWorksUsp
              img={PayTheTaxi}
              alt="Only pay per minute you sail. Your booking can be ended anytime."
              header="Boek je auto."
              title={
                "Gebruik de app om te reserveren de auto te openen en sluiten"
              }
              description="Jij bepaalt wanneer je een taxi huurt. Geen vaste kosten of verplichtingen!"
              showLine={false}
            />
          </div>
        </div>
      </InView>
    );
  }
);
