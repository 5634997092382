import React, { useContext } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/logo/header-logo.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../App";

export const Footer = ({ city }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { selectedCity, selectedVehicleType } = useContext(UserContext);

  const scrollToTop = () => {
    if (location.pathname !== "/") {
      navigate("/");
    }
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  };

  return (
    <div className="md:flex-3 flex w-full flex-col items-start justify-start bg-[#F4F4F4] py-10 pl-5 md:flex-row md:items-center md:justify-between md:px-10 md:pl-10 lg:px-[160px] lg:pb-[60px]">
      <div className="mb-10 md:mb-0">
        <img
          onClick={scrollToTop}
          alt="Vloto logo"
          src={logo}
          className="md:flex-3 mb-4 hover:cursor-pointer md:mt-6 md:scale-125 lg:mb-[34px] lg:scale-150"
        />
        <p className="text-[#12141D] opacity-[0.8]">© 2024 Vloto</p>
      </div>

      <div className="flex space-x-[60px]">
        <div className="flex flex-col text-base">
          <p className="mb-5 text-[#757474]">Locaties</p>

          <div className="flex flex-col space-y-3">
            <Link
              to="/"
              className={`mb-0 flex items-center text-base leading-[25px] text-darkGrey   ${
                selectedCity.name === "De Krijgsman" &&
                "space-x-[6px] font-bold text-[#1C1C1C]"
              }`}
            >
              <div
                className={`h-[13px] w-1 rounded-[2px] bg-[#23CE6B] ${
                  selectedCity.name !== "De Krijgsman" && "hidden"
                }`}
              ></div>
              <button>Muiden</button>
            </Link>

            <Link
              to="/ijburg"
              className={` mb-10 flex items-center text-base leading-[25px] text-darkGrey ${
                selectedCity.name === "IJburg" &&
                "space-x-[6px] font-bold text-[#1C1C1C]"
              }`}
            >
              <div
                className={`h-[13px] w-1 rounded-[2px] bg-[#23CE6B] ${
                  selectedCity.name !== "IJburg" && "hidden"
                }`}
              ></div>
              <button>IJburg</button>
            </Link>
            <Link
              to="/loenen-aan-de-vecht"
              className={` mb-10 flex items-center text-base leading-[25px] text-darkGrey ${
                selectedCity.name === "Loenen a/d Vecht" &&
                "space-x-[6px] font-bold text-[#1C1C1C]"
              }`}
            >
              <div
                className={`h-[13px] w-1 rounded-[2px] bg-[#23CE6B] ${
                  selectedCity.name !== "Loenen a/d Vecht" && "hidden"
                }`}
              ></div>
              <button>Loenen a/d Vecht</button>
            </Link>
          </div>
        </div>

        <div className="flex flex-col md:hidden">
          <p className="mb-5  text-[#757474]">Contact</p>

          <div className="flex flex-col space-y-3">
            <a
              href="tel:+312 94 30 00 03"
              className="text-base leading-[25px] text-[#494949]"
            >
              +312 94 30 00 03
            </a>

            <a
              href="mailto:info@vloto.nl"
              className="text-base leading-[25px] text-[#494949]"
            >
              info@vloto.nl
            </a>
          </div>
        </div>
      </div>

      <div className="mt-10 flex flex-col md:mt-0">
        <p className="mb-5 text-[#757474]">Juridisch</p>

        <div className="flex flex-col space-y-3">
          <Link
            to="/algemene-voorwaarden"
            className=" mb-0 text-base leading-[25px] text-darkGrey "
          >
            <button>Algemene voorwaarden</button>
          </Link>

          <Link
            to="/privacy-policy"
            className="mb-2 text-base leading-[25px] text-darkGrey md:mb-0 "
          >
            <button>Privacybeleid</button>
          </Link>
        </div>
      </div>

      <div className="hidden flex-col md:flex">
        <p className="mb-5 text-[#757474]">Contact</p>

        <div className="flex flex-col space-y-3">
          <a
            href="tel:+312 94 30 00 03"
            className="text-base leading-[25px] text-[#494949] "
          >
            +312 94 30 00 03
          </a>

          <a
            href="mailto:info@vloto.nl"
            className="text-base leading-[25px] text-[#494949] "
          >
            info@vloto.nl
          </a>
        </div>
      </div>
    </div>
  );
};
