import React, { forwardRef } from "react";
import NoDeposit from "../../assets/taxi/krijgsman-foto.png";
import { InView } from "react-intersection-observer";
import { VlotoTaxiButton } from "./VlotoTaxiButton";
import useWindowDimensions from "../../hooks/useWindowDimensions";

export const TaxiNoKms = forwardRef(
  ({ setCurrentlyInView, setShowAppModal }, ref) => {
    const { width } = useWindowDimensions();
    return (
      <InView
        className="flex flex-col justify-center  px-5 py-[80px]  lg:items-center lg:py-[110px]"
        ref={ref}
        as="div"
        threshold={width < 744 ? 0.4 : 0.7}
        onChange={(inView) => inView && setCurrentlyInView("Tarieven")}
      >
        <div className="self-center lg:w-[1440px] lg:max-w-[1440px]">
          <div className="flex w-full flex-col items-center justify-center self-center  md:max-w-[744px] md:px-[118px] lg:flex lg:w-full lg:max-w-full lg:flex-row-reverse lg:items-center lg:space-x-[150px] lg:space-x-reverse lg:self-center lg:px-[180px]">
            <img
              src={NoDeposit}
              alt="Mapbox placeholder"
              className="mb-5 w-full md:mb-10 md:w-[510px] lg:h-[397px] lg:w-[510px]"
            />
            <div className="flex-start flex w-full flex-col   md:max-w-[744px]">
              <h4 className="mb-3 w-[80%] font-DMSans text-2xl font-bold tracking-[-0.5px] md:mb-6 md:w-[309px] md:text-32 md:leading-[40px] lg:mb-8 lg:w-[309px]">
                Je betaalt slechts €5 per uur
              </h4>

              <p className=" mb-8  w-[340px] text-[#494949] md:mb-5 lg:mb-[50px] lg:w-[288px]">
                Geen kilometerkosten. Geen verzekeringskosten. Allemaal
                inbegrepen.
              </p>

              <VlotoTaxiButton onClick={setShowAppModal} />
            </div>
          </div>
        </div>
      </InView>
    );
  }
);
