import React from "react";

export const TaxiUspItem = ({ text, description }) => {
  return (
    <div className="flex space-x-[12px]  lg:max-w-[397px]">
      <div className="h-6 w-6 p-[6px]">
        <div className="h-full w-full rounded-[20px] bg-[#23CE6B]" />
      </div>
      <p className="text-[#494949]">
        <span className="font-bold text-[#1C1C1C]">{text}</span> {description}
      </p>
    </div>
  );
};
