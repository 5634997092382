import PlayVideo from "../assets/icons/playVideo.svg";

export const HeaderButton = ({ title, variant, onClick }) => {
  if (variant === "solid") {
    return (
      <button
        onClick={onClick}
        className=" h-[32px] rounded-[8px] bg-vlotoGreen px-3 font-DMSans text-[14px] font-semibold leading-[14px] text-white"
      >
        {title}
      </button>
    );
  }

  if (variant === "outline") {
    return (
      <button
        onClick={onClick}
        className="jflex ustify-center h-[48px] w-full items-center rounded-[8px] bg-white px-[24.5px] font-semibold leading-[18px] text-vlotoGreen md:w-[200px]"
      >
        {title}
      </button>
    );
  }

  if (variant === "solid-light") {
    return (
      <button
        onClick={onClick}
        className="jflex ustify-center h-[48px] w-full items-center rounded-[8px] bg-lightGreen px-[24.5px] font-semibold leading-[18px] text-vlotoGreen"
      >
        {title}
      </button>
    );
  }

  if (variant === "playVideo") {
    return (
      <button
        onClick={onClick}
        className="flex h-[48px] w-full items-center justify-center rounded-[8px] border-[1px] border-vlotoGreen bg-white px-[24.5px] font-semibold leading-[18px] text-vlotoGreen md:w-[200px]"
      >
        <img src={PlayVideo} className="mr-[18px]" alt="Video afspelen" />
        {title}
      </button>
    );
  }
};
