import React, { forwardRef } from "react";
import NoLicenseRequired from "../../assets/boats/Illustrations/no-license-required.png";
import ElectricBoats from "../../assets/boats/Illustrations/all-boats-electric.png";
import CancelReservation from "../../assets/boats/Illustrations/cancel-reservation.png";
import { BoatsUspOneCard } from "./BoatsUspOneCard";
import { InView } from "react-intersection-observer";
import useWindowDimensions from "../../hooks/useWindowDimensions";

export const BoatsUspOne = forwardRef(({ setCurrentlyInView }, ref) => {
  const { width } = useWindowDimensions();

  return (
    <InView
      ref={ref}
      as="div"
      threshold={width > 744 ? 0.6 : 0.2}
      onChange={(inView, entry) =>
        entry.isIntersecting && setCurrentlyInView("Voordelen")
      }
    >
      <div className="flex flex-col py-[60px] px-5 md:bg-[#FAFAFA] md:py-[80px] md:px-10 lg:px-[80px] lg:py-[110px] xl:px-[160px]">
        <div className="self-center md:max-w-[744px] lg:max-w-[1120px]">
          <h1 className=" mb-10 max-w-[304px] text-2xl leading-[32px] tracking-[-0.5px] md:max-w-[420px] md:text-32 md:leading-[40px] lg:w-[399px] lg:max-w-[399px] ">
            Je hebt geen vaarbewijs nodig voor een Vloto sloep
          </h1>

          <div className="grid grid-cols-1 gap-10 md:grid-cols-2 md:gap-5 lg:grid-cols-3 lg:gap-[60px]">
            <BoatsUspOneCard
              img={NoLicenseRequired}
              alt="Geen rijbewijs nodig voor de boten"
              text="Iedereen kan een Vloto boot huren, een vaarbewijs is niet nodig."
            />
            <BoatsUspOneCard
              img={ElectricBoats}
              alt={
                "All boats are electric and easy to steer with a steering wheel or a tiller."
              }
              text="Huur een boot voor zo lang jij het wilt. Je betaalt alleen voor de tijd die je gebruikt."
            />
            <BoatsUspOneCard
              img={CancelReservation}
              alt="You can cancel your reservation up to 1 hours before your start time."
              text="Maak een reservering via de app. Borg is niet nodig."
            />
          </div>
        </div>
      </div>
    </InView>
  );
});
