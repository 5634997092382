import React, { useEffect, useRef, useState } from "react";
import { BoatsHero } from "../../components/Boats/BoatsHero";
import { isAndroid, isIOS, isMobile } from "react-device-detect";
import { DownloadAppModal } from "../../components/DownloadAppModal";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { BoatsUspOne } from "../../components/Boats/BoatsUspOne";
import { BoatsUspTwo } from "../../components/Boats/BoatsUspTwo";
import { BoatsHowItWorks } from "../../components/Boats/BoatsHowItWorks";
import { BoatsNoDeposits } from "../../components/Boats/BoatsNoDeposits";
import { BoatsPriceCalculation } from "../../components/Boats/BoatsPriceCalculation";
import { BoatsLuxury } from "../../components/Boats/BoatsLuxury";
import { BoatsLocation } from "../../components/Boats/BoatsLocation";
import { BoatFooter } from "../../components/BoatFooter";
import { BoatHeader } from "../../components/BoatHeader";
import { BoatsFaqBlock } from "../../blocks/BoatsFaqBlock";
import ScrollToTop from "../../components/ScrollToTop";
import { TaxiHero } from "../../components/Taxi/TaxiHero";
import { TaxiUspOne } from "../../components/Taxi/TaxiUspOne";
import { TaxiUspThree, TaxiUspTwo } from "../../components/Taxi/TaxiUspTwo";
import { TaxiHowItWorks } from "../../components/Taxi/TaxiHowItWorks";
import { TaxiNoKms } from "../../components/Taxi/TaxiNoKms";
import { TaxiLocation } from "../../components/Taxi/TaxiLocation";
import { TaxiHeader } from "../../components/TaxiHeader";
import { TaxiFooter } from "../../components/Taxi/TaxiFooter";
import { TaxiFaqBlock } from "../../components/Taxi/TaxiFaqBlock";
import { TaxiImageSlider } from "../../components/Taxi/TaxiImageSlider";
import { DownloadTaxiAppModal } from "../../components/Taxi/DownloadTaxiAppModal";

export const scrollToTaxiSection = (ref) => {
  window.scrollTo({
    top: ref.current.node.offsetTop - 35,
    behavior: "smooth",
  });
};

export const TaxiPage = () => {
  const [showAppModal, setShowAppModal] = useState(false);
  const { width } = useWindowDimensions();
  const [isHeaderOpen, setIsHeaderOpen] = useState(false);
  const [currentlyInView, setCurrentlyInView] = useState("Home");
  const [showHeaderSectionBorder, setShowHeaderSectionBorder] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  const homeRef = useRef(null);
  const benefitsRef = useRef(null);
  const howItWorksRef = useRef(null);
  const priceRef = useRef(null);
  const ourBoatRef = useRef(null);
  const locationRef = useRef(null);
  const faqRef = useRef(null);

  const showAppDownloadModal = () => {
    if (!isMobile) {
      setShowAppModal(true);
      return;
    }

    if (isAndroid) {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.vloto.vlototaxi";
    } else if (isIOS) {
      window.location.href = "https://apps.apple.com/us/app/vloto/id6476565206";
    }
  };

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (scrollPosition > 40) {
      setShowHeaderSectionBorder(true);
    } else {
      setShowHeaderSectionBorder(false);
    }
  }, [scrollPosition]);

  return (
    <div>
      <ScrollToTop />

      {showAppModal && (
        <Popup
          contentStyle={{
            backgroundColor: "transparent",
            border: "none",
            width: width > 1260 ? 1120 : 744,
          }}
          open={showAppModal}
          onClose={() => setShowAppModal(false)}
        >
          <DownloadTaxiAppModal setShowAppModal={setShowAppModal} />
        </Popup>
      )}

      <TaxiHeader
        isHeaderOpen={isHeaderOpen}
        setIsHeaderOpen={setIsHeaderOpen}
        currentlyInView={currentlyInView}
        showHeaderSectionBorder={showHeaderSectionBorder}
        homeRef={homeRef}
        benefitsRef={benefitsRef}
        howItWorksRef={howItWorksRef}
        priceRef={priceRef}
        ourBoatRef={ourBoatRef}
        locationRef={locationRef}
        faqRef={faqRef}
      />
      <div
        onTouchStart={() => setIsHeaderOpen(false)}
        onClick={() => setIsHeaderOpen(false)}
        className={`z-40  ${
          isHeaderOpen &&
          "bg-white opacity-10 transition-opacity duration-200 ease-in-out"
        } `}
      >
        <TaxiHero
          ref={homeRef}
          setCurrentlyInView={setCurrentlyInView}
          setShowAppModal={showAppDownloadModal}
        />

        <TaxiUspOne ref={benefitsRef} setCurrentlyInView={setCurrentlyInView} />

        <TaxiHowItWorks
          ref={howItWorksRef}
          setShowAppModal={showAppDownloadModal}
          setCurrentlyInView={setCurrentlyInView}
        />

        <TaxiUspTwo
          setShowAppModal={showAppDownloadModal}
          setCurrentlyInView={setCurrentlyInView}
        />

        <TaxiNoKms
          ref={priceRef}
          setCurrentlyInView={setCurrentlyInView}
          setShowAppModal={showAppDownloadModal}
        />

        <div className="flex w-full md:items-center md:justify-center">
          <TaxiImageSlider />
        </div>

        <TaxiLocation
          ref={locationRef}
          setShowAppModal={showAppDownloadModal}
          setCurrentlyInView={setCurrentlyInView}
        />

        <div className="w-full">
          <TaxiFaqBlock
            ref={faqRef}
            type="taxi"
            setCurrentlyInView={setCurrentlyInView}
          />
        </div>
        <TaxiFooter setShowAppModal={showAppDownloadModal} />
      </div>
    </div>
  );
};
