import React, { forwardRef } from "react";
import BookTheBoat from "../../assets/boats/Illustrations/book-a-boat.png";
import UnlockTheBoat from "../../assets/boats/Illustrations/unlock-the-boat.png";
import PayTheBoat from "../../assets/boats/Illustrations/pay-for-the-boat.png";
import { BoatsHowItWorksUsp } from "./BoatsHowItWorksUsp";
import { InView } from "react-intersection-observer";
import useWindowDimensions from "../../hooks/useWindowDimensions";

export const BoatsHowItWorks = forwardRef(
  ({ setShowAppModal, setCurrentlyInView }, ref) => {
    const { width } = useWindowDimensions();
    return (
      <InView
        ref={ref}
        as="div"
        threshold={width < 1024 ? 0.2 : 0.4}
        onChange={(inView, entry) =>
          entry.isIntersecting && setCurrentlyInView("Hoe werkt het")
        }
      >
        <div className="flex flex-col items-center py-[80px] px-5 lg:py-[110px]">
          <h4 className="mb-8 font-DMSans text-2xl font-bold tracking-[-0.5px] md:mb-[50px] lg:mb-[132px]">
            Hoe het werkt
          </h4>

          <div className=" grid grid-cols-1 gap-10 lg:grid-cols-3">
            <BoatsHowItWorksUsp
              img={BookTheBoat}
              alt="Download de app"
              header="Download de app"
              title="en kies een datum en tijd om te reserveren"
              description="Check de kalender om te zien wanneer de boten nog vrij zijn"
              onClick={setShowAppModal}
              showLine={true}
            />

            <BoatsHowItWorksUsp
              img={UnlockTheBoat}
              alt="Be your own captain. Unlock / lock boat with our app."
              header="Jij bent de kapitein."
              title="Open / sluit de boot met de app"
              description={
                "De boten zijn elektrisch, de accu is groot genoeg om de hele dag te varen"
              }
              showLine={true}
            />

            <BoatsHowItWorksUsp
              img={PayTheBoat}
              alt="Only pay per minute you sail. Your booking can be ended anytime."
              // header="Je betaalt alleen voor je daadwerkelijke gebruik."
              title={"Je betaalt alleen voor je daadwerkelijke gebruik."}
              description="De kosten bedragen €35 per uur, maar je wordt afgerekend per minuut
            "
              showLine={false}
            />
          </div>
        </div>
      </InView>
    );
  }
);
