import React, { useState, forwardRef } from "react";
import { InView } from "react-intersection-observer";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import ReactPlayer from "react-player";
import VideoThumbnail from "../assets/videothumbnail.png";
import useWindowDimensions from "../hooks/useWindowDimensions";

export const HowItWorksBlock = forwardRef(({ setCurrentlyInView }, ref) => {
  const [showVideoThumbnail, setShowVideoThumbnail] = useState(true);
  const [playVideo, setPlayVideo] = useState(false);
  const { width } = useWindowDimensions();

  const startVideo = () => {
    setPlayVideo(true);
    setShowVideoThumbnail(false);
  };

  return (
    <InView
      className="w-full  md:max-w-[744px] lg:max-w-[1120px]"
      ref={ref}
      as="div"
      threshold={0.8}
      onChange={(inView, entry) =>
        entry.isIntersecting && setCurrentlyInView("Hoe werkt het")
      }
    >
      <LazyLoadComponent threshold={400}>
        <div className=" relative flex h-[310px] w-full min-w-[334px] items-center justify-center self-center rounded-[16px] md:h-[360px] md:w-[664px] md:rounded-[30px] lg:h-[520px] lg:min-w-[1120px]">
          {showVideoThumbnail && (
            <div
              onClick={startVideo}
              className="absolute h-[310px] w-full min-w-[334px] cursor-pointer  self-center rounded-[16px] md:h-[360px] md:rounded-[30px] lg:h-[520px] "
              style={{
                background: `linear-gradient(0deg, rgba(0,0,0,1) 10%, rgba(0,0,0,0) 50%), url(${VideoThumbnail})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                borderRadius: width <= 1280 ? 16 : 30,
              }}
            >
              <div className="absolute bottom-[24px] w-full px-6 md:bottom-[32px] md:px-8 lg:bottom-[80px] lg:px-20">
                <p className="mb-1 font-DMSans text-xl font-bold leading-[28px] tracking-[-0.5px] text-white lg:mb-3 lg:text-[32px] lg:leading-[40px]">
                  Hoe werkt Vloto?
                </p>

                <p className="pr-6 text-[#EBECEA] lg:text-lg">
                  Download de app, schrijf je in en stap in!
                </p>
              </div>
            </div>
          )}

          <ReactPlayer
            playing={playVideo}
            url="https://youtu.be/1xiSWIT0ie0"
            muted={false}
            width={"100%"}
            height={"100%"}
            volume={1}
            controls={true}
            style={{
              borderRadius: width < 744 ? 16 : 40,
              overflow: "hidden",
            }}
          />
        </div>
      </LazyLoadComponent>
    </InView>
  );
});
