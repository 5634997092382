import React, { useState } from "react";

import "swiper/css";
import "swiper/css/navigation";

import CarLabel from "./CarLabel";
import CarGalleryModal from "./CarGalleryModal/CarGalleryModal";
import GalleryIcon from "../../assets/icons/GalleryIcon.svg";
import useWindowDimensions from "../../hooks/useWindowDimensions";

export const CarInformationCard = ({ car }) => {
  const [openGallery, setOpenGallery] = useState(false);
  const { width } = useWindowDimensions();

  if (width <= 1260) return null;
  return (
    <>
      <div className=" absolute ">
        {openGallery && (
          <CarGalleryModal
            car={car}
            openModal={openGallery}
            setOpenModal={setOpenGallery}
          />
        )}
      </div>

      <div
        onClick={() => setOpenGallery(true)}
        className="relative h-[480px] hover:cursor-pointer"
        style={{
          background: `linear-gradient(0deg, rgba(0,0,0,1) 10%, rgba(0,0,0,0) 50%), url(${car?.img})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          borderRadius: 30,
        }}
      >
        <div className="absolute right-8 top-8 flex cursor-pointer items-center space-x-2 rounded-[29px] bg-[#494949] py-3 px-4">
          <img src={GalleryIcon} alt="Galerij" />
          <p className="leading-[18px] text-white ">Galerij</p>
        </div>

        <div className="absolute bottom-10 left-6 md:bottom-12 md:left-12 lg:left-12 lg:bottom-36">
          <CarLabel car={car} />
        </div>
      </div>
    </>
  );
};
