import { Button } from "./Button";
import DealLabel from "./DealLabel";

export const AppUSPCard = ({
  image,
  title,
  description,
  showAppDownloadModal,
}) => {
  return (
    <div className="flex flex-col justify-center overflow-hidden rounded-[16px] bg-vlotoGreen pt-[32px] md:col-span-2 md:h-[360px] md:w-full">
      <div className="mb-[32px] flex flex-col items-center">
        <DealLabel />
        <h5 className="mb-2 mt-2 text-white">{title}</h5>

        <p className="px-6 text-center text-[#D6D9D5]">{description}</p>
      </div>

      <div className="mb-[26px] px-[45px]">
        <Button
          onClick={showAppDownloadModal}
          title={"Download de app"}
          variant="solid-light"
        />
      </div>

      <img src={image} className="" alt="De Vloto app" />
    </div>
  );
};
