import React from "react";
import { InView } from "react-intersection-observer";
import BookingScreen from "../../assets/boats/Illustrations/booking-screen.png";
import { BoatUspItem } from "./BoatUspItem";

export const BoatsUspTwo = ({ setShowAppModal, setCurrentlyInView }) => {
  return (
    <InView
      as="div"
      threshold={0.8}
      onChange={(inView) => inView && setCurrentlyInView("Voordelen")}
    >
      <div className="flex flex-col py-[80px] px-5  lg:py-[110px] lg:px-[160px]">
        <div className=" self-center   lg:flex lg:max-w-[1260px] lg:space-x-[144px]">
          <div className="flex-shrink-0 md:mb-10 md:h-[535px] md:w-[510px]">
            <img
              src={BookingScreen}
              alt="Boek je Vloto auto of boot"
              className="mb-6 w-full flex-shrink-0"
            />
          </div>

          <div>
            <div className="mb-8 space-y-3 md:mb-10 lg:mb-[50px] lg:space-y-4">
              <p className="w-[95%] font-DMSans text-2xl font-bold tracking-[-0.5px] md:mb-6 md:w-[279px] md:text-32 md:leading-[40px] lg:mb-8 lg:text-32">
                Volledige toegang met de Vloto app
              </p>

              <BoatUspItem
                text="Niet alleen een boot."
                description={"Je kan ook een auto huren."}
              />

              <BoatUspItem
                text="Reserveer."
                description={"Direct of voor in de toekomst, wat jou uitkomt."}
              />

              <BoatUspItem
                text="Ophalen en terugbrengen."
                description={"Zonder check-in of check-out."}
              />

              <BoatUspItem
                text="Start en stop"
                description={"de boot met de app."}
              />

              <BoatUspItem
                text="Betaal."
                description={"Alleen voor de daadwerkelijk gevaren tijd."}
              />
            </div>
            <button
              className="h-[48px] w-[166px] rounded-[8px] bg-vlotoGreen font-semibold text-white"
              onClick={setShowAppModal}
            >
              Download de app
            </button>
          </div>
        </div>
      </div>
    </InView>
  );
};
