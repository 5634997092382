import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import TagManager from "react-gtm-module";
import { App } from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));

const tagManagerArgs = {
  gtmId: "G-EXCJNJ8W2P",
};

TagManager.initialize(tagManagerArgs);

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
