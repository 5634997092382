import React from "react";

export const BoatsUspOneCard = ({ img, alt, text }) => {
  return (
    <div className="space-y-5">
      <img src={img} alt={alt} className="w-full" />
      <p className="w-[90%] text-[#494949] lg:w-[267px] lg:text-[20px] lg:leading-[32px]">
        {text}
      </p>
    </div>
  );
};
