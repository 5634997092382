import CloseModal from "../../assets/icons/close.svg";
import ModalContent from "../../assets/taxi/modal-content-new.png";
import WebsiteQR from "../../assets/taxi/qr-website.png";
import Arrow from "../../assets/illustrations/modal-arrow.png";
import appStore from "../../assets/icons/Appstore.png";
import playStore from "../../assets/icons/google-play-badge.png";
import useLockBodyScroll from "../../hooks/useLockBodyScroll";

export const DownloadTaxiAppModal = ({ setShowAppModal }) => {
  useLockBodyScroll();

  return (
    <div className=" flex h-[564px] items-center justify-center space-x-10 rounded-[40px] bg-white pr-6 md:w-[744px] md:scale-75 lg:w-[1120px] lg:scale-100 lg:justify-start">
      <img
        src={ModalContent}
        alt="Download de app"
        className="hidden h-full rounded-[40px] lg:block"
      />
      <div className="relative mt-6">
        <h1 className="text-44">Download de app</h1>
        <p className="my-6 w-[32ch] text-xl">
          De Vloto Taxi app is live en te downloaden voor iOS en Android.
        </p>
        <p className="text-xl font-semibold">
          Scan de QR code om de app te downloaden
        </p>

        <div className="flex items-center space-x-4">
          <div className="flex shrink-0 flex-col">
            <a
              href="https://apps.apple.com/us/app/vloto/id6476565206"
              onClick={() => {
                window?.dataLayer?.push({
                  "event": "promotionClick",
                  "gtm.elementUrl":
                    "https://apps.apple.com/us/app/vloto/id6476565206",
                });
              }}
            >
              <img
                src={appStore}
                alt="Apple App store promotion"
                className="mb-2 w-[205px] hover:cursor-pointer sm:mb-4 md:ml-2 lg:ml-0 lg:mb-4"
              />
            </a>

            <a
              href="https://play.google.com/store/apps/details?id=com.vloto.vlototaxi"
              onClick={() => {
                window?.dataLayer?.push({
                  "event": "promotionClick",
                  "gtm.elementUrl":
                    "https://play.google.com/store/apps/details?id=com.vloto.vlototaxi",
                });
              }}
            >
              <img
                src={playStore}
                alt="Google Play store promotion"
                className="mb-2 w-[205px] hover:cursor-pointer sm:mb-8 md:ml-2 lg:ml-0 lg:mb-4"
              />
            </a>
          </div>
          <img src={WebsiteQR} alt="Download de app" />
        </div>
      </div>
      <img
        src={Arrow}
        alt="Pijl dat wijst naar de QR code om de app te downloaden"
        className="absolute right-24 bottom-44 hidden lg:block"
      />
      <img
        src={CloseModal}
        alt="Close modal"
        height={24}
        width={24}
        className="absolute right-8 top-8 hover:cursor-pointer"
        onClick={() => setShowAppModal(false)}
      />
    </div>
  );
};
