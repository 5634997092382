import React, { forwardRef } from "react";
import { Button } from "../Button";
import VlotoBootImageMobile from "../../assets/taxi/vloto-taxi.png";
import VlotoTaxiLogo from "../../assets/taxi/vlototaxi.svg";
import { InView } from "react-intersection-observer";
import { VlotoTaxiButton } from "./VlotoTaxiButton";

export const TaxiHero = forwardRef(
  ({ setShowAppModal, setCurrentlyInView }, ref) => {
    return (
      <InView
        ref={ref}
        as="div"
        threshold={0.8}
        onChange={(inView, entry) =>
          entry.isIntersecting && setCurrentlyInView("Home")
        }
      >
        <div className=" flex w-full flex-col px-5 pt-[111px] pb-[26px] md:items-center lg:h-[578px] lg:py-[110px] lg:px-[80px] lg:pt-[156px] xl:px-[160px]">
          <div className="self-center lg:max-w-[1440px]">
            <div className="lg:flex lg:flex-grow-0 lg:flex-row lg:space-x-[143px]">
              <div className="lg:flex lg:flex-col">
                <img
                  src={VlotoTaxiLogo}
                  alt="Vloto Taxi"
                  className="mb-3 h-full w-1/2 lg:h-[43px] lg:w-[261px] "
                />
                <h1 className="mb-5 max-w-[270px] font-DMSans text-[36px] leading-[40px] tracking-[-0.5px] md:max-w-[400px] lg:mb-6 lg:w-[464px] lg:max-w-[464px] lg:whitespace-pre-wrap lg:text-[56px] lg:leading-[64px]">
                  Huur een elektrische taxi
                </h1>

                <p className="mb-8 max-w-[250px] text-[20px] text-[#494949] lg:mb-[50px] lg:w-[323px] lg:max-w-[350px] lg:max-w-[323px] lg:text-[#757474]">
                  Betaal per uur, zonder vaste kosten.
                </p>

                <div className="mb-[50px]">
                  <VlotoTaxiButton onClick={setShowAppModal} />
                </div>
              </div>
              <div className="flex  md:h-[400px] md:w-[513px] md:self-center  lg:h-[538px] lg:w-[513px]">
                <img
                  src={VlotoBootImageMobile}
                  alt="Vloto Taxi"
                  className="h-full w-full md:rounded-[20px] md:object-cover "
                />
              </div>
            </div>
          </div>
        </div>
      </InView>
    );
  }
);
