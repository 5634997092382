import React from "react";
import { SmallNewLabel } from "./SmallNewLabel";

export const CityLabel = ({ city, hasNewLabel, selected, onClick }) => {
  return (
    <div
      onClick={onClick}
      className=" flex cursor-pointer items-center space-x-[6px] "
    >
      {selected && (
        <div className="h-[13px] w-1 rounded-[2px] bg-[#23CE6B]"></div>
      )}
      <p className={`${selected && "font-bold"} text-[#494949]`}>{city}</p>

      {hasNewLabel && <SmallNewLabel />}
    </div>
  );
};
