import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/logo/header-logo.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../../App";
import { SmallNewLabel } from "../SmallNewLabel";
import { areas } from "../../utils/areas";
import { VehicleLabel } from "../VehicleLabel";
import { CityLabel } from "../CityLabel";
import { VlotoTaxiButton } from "./VlotoTaxiButton";

export const TaxiFooter = ({ city, setShowAppModal }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { selectedCity, selectedVehicleType } = useContext(UserContext);

  const scrollToTop = () => {
    if (location.pathname !== "/") {
      navigate("/");
    }
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  };

  let vehicles = [];

  if (
    selectedCity.name === "Loenen a/d Vecht" ||
    selectedCity.name === "IJburg" ||
    selectedCity.name === "Weespersluis" ||
    selectedCity.name === "Muiden Vesting"
  ) {
    vehicles = [{ id: 1, name: "Auto's", slug: "/", type: "car", new: false }];
  } else {
    vehicles = [
      { id: 1, name: "Auto's", slug: "/", type: "car", new: false },
      { id: 2, name: "Boten", slug: "/boten", type: "boat", new: true },
    ];
  }

  let usedAreas = [];

  if (location.pathname.includes("/boten")) {
    usedAreas = areas.filter((area) => area.hasBoats);
  } else {
    usedAreas = areas;
  }

  return (
    <div className="bg-[#F4F4F4] px-5 pt-[60px] pb-10 md:px-10 md:pt-[80px] lg:px-[160px] ">
      <div className="lg:mx-auto lg:max-w-[1260px]">
        <div className="mb-10 w-full rounded-[16px] bg-vlotoGreen px-[45px] pt-6 pb-8 text-center text-[20px] font-bold leading-[28px] text-white md:flex md:items-center md:justify-between md:py-12 md:pl-10 md:pr-12 lg:mb-[110px] lg:px-[50px] ">
          <p className="mb-5 font-DMSans md:mb-0 md:w-[285px] md:items-center md:text-start md:text-2xl lg:w-[432px] lg:w-[466px] lg:text-32 lg:leading-[40px]">
            Download de app om onze auto's te reserveren
          </p>

          <VlotoTaxiButton
            bg="bg-white"
            onClick={() => setShowAppModal(true)}
          />
        </div>

        <div className="md:flex md:items-start">
          <div className="mb-10 md:mb-0">
            <img
              onClick={scrollToTop}
              alt="Vloto logo"
              src={logo}
              className="mb-4 hover:cursor-pointer md:mb-8 md:ml-3  md:scale-125 lg:ml-6 lg:mb-[34px] lg:scale-150"
            />
            <p className="text-[#494949] md:mr-[113px] md:w-[102px] lg:mr-[146px] lg:w-[175px]">
              Luxe deelauto's
            </p>
          </div>

          <div
            className="grid grid-cols-2  gap-y-[40px] md:mt-0 md:grid-cols-3 lg:gap-x-[150px]
            "
          >
            <div className=" md:mb-0">
              <p className="mb-5 text-[#757474]">Producten</p>
              <div className="space-y-3">
                <VehicleLabel
                  onClick={() => navigate("/")}
                  vehicle={"Auto's"}
                  selected={false}
                  hasNewLabel={false}
                />
                <VehicleLabel
                  onClick={() => navigate("/")}
                  vehicle={"Boten"}
                  selected={false}
                  hasNewLabel={false}
                />
                <VehicleLabel
                  onClick={() => navigate("/")}
                  vehicle={"Voor taxichauffeurs"}
                  selected={true}
                  hasNewLabel={true}
                />
              </div>
            </div>

            <div>
              <p className="mb-5 text-[#757474]">Locaties</p>

              <div className="space-y-3">
                <CityLabel
                  city="Amsterdam RAI"
                  onClick={() => navigate("/taxi")}
                  hasNewLabel={true}
                  selected={true}
                />
              </div>
            </div>

            <div className="mb-12 md:mb-10">
              <p className="mb-5  text-[#757474]">Contact</p>

              <div className="flex flex-col space-y-3">
                <a
                  href="tel:+312 94 30 00 03"
                  className="text-base leading-[25px] text-[#494949] lg:whitespace-nowrap"
                >
                  +312 94 30 00 03
                </a>

                <a
                  href="mailto:info@vloto.nl"
                  className="text-base leading-[25px] text-[#494949]"
                >
                  info@vloto.nl
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="md:flex md:items-center lg:w-full lg:justify-between">
          <div className="">
            <p className="text-[#12141D] opacity-[0.8]">© 2024 Vloto</p>
          </div>

          <div className="mt-3 flex space-x-3 md:mt-0  md:ml-[85px] md:space-x-[15px]">
            <Link to="/algemene-voorwaarden">
              <p className="text-[#494949]">Algemene voorwaarden</p>
            </Link>

            <Link to="/privacy-policy">
              <p className="text-[#494949]">Privacybeleid</p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
