import { Pagination, Navigation, Mousewheel } from "swiper";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { Swiper, SwiperSlide } from "swiper/react";
import { cars } from "../content/cars";
import { CarInformationCard } from "../components/CarPicker/CarInformationCard";
import Left from "../assets/icons/left.png";
import Right from "../assets/icons/right.png";

import "swiper/css";
import "swiper/css/navigation";

import "../styles/styles.css";
import CarInformationCardMobile from "../components/CarPicker/CarInformationCardMobile";
import { forwardRef, useCallback, useEffect, useState } from "react";
import LazyLoad from "react-lazy-load";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { InView } from "react-intersection-observer";

export const CarBlock = forwardRef(({ setCurrentlyInView }, ref) => {
  const { width } = useWindowDimensions();
  const [swiperRef, setSwiperRef] = useState();
  const [currentSlide, setCurrentSlide] = useState(0);

  const handlePrevious = useCallback(() => {
    swiperRef?.slidePrev();
  }, [swiperRef]);

  const handleNext = useCallback(() => {
    swiperRef?.slideNext();
  }, [swiperRef]);

  const isInView = () => {
    if (width < 744) {
      return 0.3;
    } else if (width > 744 && width < 1260) {
      return 0.8;
    } else if (width >= 1260) {
      return 0.2;
    }
  };

  return (
    <InView
      ref={ref}
      className="flex min-h-[746px] w-full flex-col justify-center py-[80px] md:min-h-[760px]"
      as="div"
      threshold={isInView()}
      onChange={(inView, entry) =>
        entry.isIntersecting && setCurrentlyInView(`Onze auto's`)
      }
    >
      <h5 className="mb-[30px] min-w-[276px] scroll-mt-[300px] px-10 lg:mb-[11px] lg:w-[662px] lg:scroll-mt-[80px] lg:self-center lg:px-0">
        Maak kennis met onze 100% elektrische deelauto's
      </h5>
      <div className="relative flex flex-col">
        <LazyLoadComponent visibleByDefault={false}>
          <div className=" hidden justify-end  self-center  lg:flex lg:w-[1120px]">
            <div className="flex space-x-[10px] lg:mb-[30px]">
              <button
                className={`h-10 w-10 ${
                  currentSlide === 0 && "cursor-default"
                }`}
                onClick={handlePrevious}
              >
                <img src={Left} />
              </button>
              <button
                className={`h-10 w-10 ${
                  currentSlide === 2 && "cursor-default"
                }`}
                onClick={handleNext}
              >
                <img src={Right} />
              </button>
            </div>
          </div>
          <Swiper
            onSwiper={setSwiperRef}
            style={{
              "--swiper-navigation-size": "18px",
              paddingLeft: 20,
              paddingRight: 20,
            }}
            onSlideChange={(slide) => setCurrentSlide(slide.activeIndex)}
            preloadImages={false}
            lazy={true}
            spaceBetween={24}
            slideToClickedSlide={width < 1260 ? true : false}
            className="w-full "
            slidesPerView={width < 1260 ? 1.12 : 2.25}
            modules={[Pagination, Navigation, Mousewheel]}
            mousewheel={{ forceToAxis: true }}
            // navigation={width < 1260 ? false : true}
          >
            {cars.map((car) => (
              <SwiperSlide key={car.id}>
                <CarInformationCardMobile car={car} />

                <CarInformationCard key={car.id} car={car} />
              </SwiperSlide>
            ))}
          </Swiper>
        </LazyLoadComponent>
      </div>
    </InView>
  );
});
