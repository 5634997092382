import React from "react";

export const TaxiSectionTag = ({ section, currentlyInView, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={` flex cursor-pointer snap-center border-[1px] border-transparent px-[10px] pt-[2px] pb-1 ${
        currentlyInView && "rounded-[26px]  border-[#E8E8E8] bg-[#F4F4F4]"
      }`}
    >
      <p
        className={` whitespace-nowrap  text-sm font-semibold leading-[22px] ${
          currentlyInView ? "text-[#1C1C1C]" : "text-[#A4A4A4]"
        }`}
      >
        {section}
      </p>
    </div>
  );
};
