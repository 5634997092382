import { USPCardLarge } from "../components/USPCardLarge";
import LargeUSP from "../assets/illustrations/USPCards/LargeApp-usp.png";
import FamilyUSP from "../assets/illustrations/USPCards/Family-usp.png";
import PhoneUSP from "../assets/illustrations/USPCards/Phone-usp.png";
import PhoneUSPLarge from "../assets/illustrations/USPCards/PhoneUSPLarge.png";

import { AppUSPCard } from "../components/AppUSPCard";
import { AppUSPCardLarge } from "../components/AppUSPCardLarge";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { forwardRef } from "react";
import { InView } from "react-intersection-observer";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { HowItWorksBlock } from "./HowItWorksBlock";
import { Button } from "../components/Button";
import DealLabel from "../components/DealLabel";

export const AppUseBlock = forwardRef(
  ({ showAppDownloadModal, setCurrentlyInView }, ref) => {
    const { width } = useWindowDimensions();
    return (
      <InView
        className="min-h-[1568px] w-full py-[80px] px-5  md:min-h-[1020px] md:max-w-[744px] md:px-10 lg:min-h-[1464px] lg:max-w-[1120px] lg:py-[140px] lg:px-0"
        ref={ref}
        as="div"
        threshold={width < 744 ? 0.2 : 0.2}
        onChange={(inView, entry) =>
          entry.isIntersecting && setCurrentlyInView("Onze app")
        }
      >
        <div className="mb-10 flex flex-col items-center px-[26px] md:px-[110px] lg:mb-20">
          <h5 className=" mb-5 text-center">
            Een simpel te gebruiken en overzichtelijke app
          </h5>

          <p className="text-center lg:w-[600px] lg:text-[20px] lg:leading-[32px]">
            Via de Vloto app kun je in een handomdraai je favoriete luxe auto
            huren. Reserveer je auto, open deze via de app en geniet van ultiem
            rijcomfort voor een heel scherp tarief. Onze auto’s staan
            altijd rijklaar op je te wachten op loopafstand.
          </p>
        </div>

        <LazyLoadComponent threshold={300}>
          <div className="space-y-4 md:grid md:grid-cols-9 md:gap-5 md:space-y-0 lg:w-[1120px] lg:grid-cols-3 lg:gap-x-4 lg:gap-y-6">
            <div className="md:col-span-5 lg:col-span-2">
              <USPCardLarge
                image={LargeUSP}
                title="Mobiliteit in je broekzak"
                description="Rijd je graag met het hele gezin in de luxe deelauto’s van Vloto? Maak dan een account aan voor je huishouden en geniet van alle voordelen zonder dubbel te betalen!"
              />
            </div>

            <div className="md:col-span-4 lg:col-span-1">
              <USPCardLarge
                comingSoon={true}
                image={FamilyUSP}
                title="Vloto voor het hele gezin"
                description="Rijd je graag met het hele gezin in de luxe deelauto’s van Vloto? Maak dan een account aan voor je huishouden en geniet van alle voordelen zonder dubbel te betalen!"
              />
            </div>
          </div>

          <div className="flex flex-col justify-center">
            <div className="mt-6  flex flex-col items-center justify-center self-center md:mb-[140px] lg:mt-10 lg:flex-row lg:items-center">
              <div className="mb-2 w-[221px] lg:mb-0 lg:flex">
                <Button
                  onClick={showAppDownloadModal}
                  variant={"solid"}
                  title={"Download de app"}
                />
              </div>
              <DealLabel />
            </div>

            <div className="mt-[140px] mb-4 flex w-full items-center justify-center md:hidden lg:mt-[280px]">
              <HowItWorksBlock />
            </div>

            <div className="w-full md:hidden">
              <AppUSPCard
                showAppDownloadModal={showAppDownloadModal}
                image={PhoneUSP}
                title="Rijd vandaag met Vloto"
                description="Download de app in Apple Appstore of Google Playstore en stap vandaag nog in een van onze premium deelauto's."
              />
            </div>

            <div className="mb-5 hidden w-full md:flex md:max-w-[744px] lg:mb-6  ">
              <HowItWorksBlock />
            </div>

            <div className="hidden md:col-span-9 md:flex lg:col-span-3 lg:w-[1120px] lg:max-w-[1120px]">
              <AppUSPCardLarge
                image={PhoneUSPLarge}
                title="Rijd vandaag met Vloto"
                description="Download de app in Apple Appstore of Google Playstore en stap vandaag nog in een van onze premium deelauto's."
              />
            </div>
          </div>
        </LazyLoadComponent>
      </InView>
    );
  }
);
