import { useState } from "react";

import "swiper/css";
import "swiper/css/navigation";

import GalleryIcon from "../../assets/icons/GalleryIcon.svg";

import useWindowDimensions from "../../hooks/useWindowDimensions";
import CarLabel from "./CarLabel";
import CarGalleryModal from "./CarGalleryModal/CarGalleryModal";

const CarInformationCardMobile = ({ car }) => {
  const [openCarGalleryMobile, setOpenCarGalleryMobile] = useState(false);
  const [openCarGalleryDesktop, setOpenCarGalleryDesktop] = useState(false);
  const { width } = useWindowDimensions();

  if (width > 1260) return null;

  return (
    <>
      <div className="flex lg:hidden">
        {openCarGalleryMobile && (
          <CarGalleryModal
            car={car}
            openModal={openCarGalleryMobile}
            setOpenModal={setOpenCarGalleryMobile}
          />
        )}
      </div>

      <div
        onClick={() =>
          width < 1260 && setOpenCarGalleryMobile(!openCarGalleryMobile)
        }
        className="flex flex-col items-start justify-center"
      >
        <div className="absolute top-5 right-5 z-20 block rounded-[29px] bg-[#383838] lg:hidden">
          <div className="flex space-x-2 px-4 py-[12px] font-Manrope font-semibold hover:cursor-pointer">
            <img src={GalleryIcon} alt="Gallery Icon" />
            <p className="text-md text-white">Galerij</p>
          </div>
        </div>

        <div
          className={`absolute bottom-36 right-16 z-20 hidden rounded-[29px] bg-[#383838] lg:block xl:bottom-40 ${
            openCarGalleryDesktop && "lg:block"
          }`}
        >
          <div
            className="flex space-x-2 px-4 py-[15px] font-Manrope font-semibold hover:cursor-pointer"
            onClick={() => setOpenCarGalleryDesktop(!openCarGalleryDesktop)}
          >
            <img src={GalleryIcon} alt="Gallery Icon" />
            <p className="text-md text-white">
              {openCarGalleryDesktop ? "Overzicht" : "Galerij"}
            </p>
          </div>
        </div>

        <div className="relative h-[450px] w-full md:h-[506px] lg:h-[480px] 2xl:h-[600px]">
          <div
            className="absolute h-[450px] w-full md:h-[506px] lg:h-[480px] 2xl:h-[600px] "
            style={{
              background: `linear-gradient(0deg, rgba(0,0,0,1) 10%, rgba(0,0,0,0) 50%), url(${car?.img})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              borderRadius: 16,
            }}
          >
            <div className="absolute bottom-[140px] left-6 md:left-10 md:bottom-[150px] md:w-full">
              <CarLabel car={car} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CarInformationCardMobile;
