import React, { useEffect, useRef, useState } from "react";
import { scrollToSection } from "../../pages/Boats/BoatPage";
import { TaxiSectionTag } from "./TaxiSectionTag";
import { scrollToTaxiSection } from "../../pages/TaxiPage/TaxiPage";

export const TaxiSectionHeader = ({
  currentlyInView,
  showHeaderSectionBorder,
  homeRef,
  benefitsRef,
  howItWorksRef,
  priceRef,
  locationRef,
  faqRef,
}) => {
  const sections = [
    {
      id: 1,
      name: "Home",
      ref: homeRef,
    },
    {
      id: 2,
      name: "Voordelen",
      ref: benefitsRef,
    },
    {
      id: 3,
      name: "Hoe werkt het",
      ref: howItWorksRef,
    },
    {
      id: 4,
      name: "Tarieven",
      ref: priceRef,
    },
    {
      id: 5,
      name: "Locatie",
      ref: locationRef,
    },
    {
      id: 6,
      name: "FAQ",
      ref: faqRef,
    },
  ];

  const scrollRef = useRef(null);
  const emptyRef = useRef(null);

  const [currentIndex, setCurrentIndex] = useState(1);
  const [scrollTo, setScrollTo] = useState(null);

  useEffect(() => {
    const newIndex = sections.find(
      (section) => section.name === currentlyInView
    );

    setCurrentIndex(newIndex.id);
  }, [currentlyInView]);

  useEffect(() => {
    if (!scrollTo) {
      if (currentIndex > 3) {
        scrollRef.current.scrollTo({ left: 160, behavior: "smooth" });
      } else if (currentIndex <= 3) {
        scrollRef.current.scrollTo({ left: 0, behavior: "smooth" });
      }
    } else {
      if (
        scrollTo === "Prijs" ||
        scrollTo === "Onze boot" ||
        scrollTo === "Locatie" ||
        scrollTo === "FAQ"
      ) {
        scrollRef.current.scrollTo({ left: 160, behavior: "smooth" });
      } else {
        scrollRef.current.scrollTo({ left: 0, behavior: "smooth" });
      }
      setTimeout(() => {
        setScrollTo(null);
      }, 500);
    }
  }, [currentlyInView, currentIndex]);
  return (
    <div
      className={`absolute flex h-11  w-full snap-x snap-mandatory snap-center items-center  space-x-2  bg-white px-5  ${
        showHeaderSectionBorder && "border-b-[1px] border-card shadow-header"
      }`}
    >
      <div
        ref={scrollRef}
        className="-mx-5 flex snap-x snap-center space-x-2 overflow-x-scroll px-5 scrollbar-hide lg:mx-auto"
      >
        {sections.map((section) => (
          <div key={section.id}>
            <TaxiSectionTag
              section={section.name}
              currentlyInView={currentlyInView === section.name}
              onClick={() => {
                setScrollTo(section.name);
                scrollToTaxiSection(section.ref);
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
