import React, { forwardRef } from "react";
import { Button } from "../Button";
import VlotoBootImageMobile from "../../assets/photos/Boten/vloto-boot.png";
import { InView } from "react-intersection-observer";

export const BoatsHero = forwardRef(
  ({ setShowAppModal, setCurrentlyInView }, ref) => {
    return (
      <InView
        ref={ref}
        as="div"
        threshold={0.8}
        onChange={(inView, entry) =>
          entry.isIntersecting && setCurrentlyInView("Home")
        }
      >
        <div className=" flex w-full flex-col px-5 pt-[111px] pb-[26px] md:items-center lg:h-[578px] lg:py-[110px] lg:px-[80px] lg:pt-[156px] xl:px-[160px]">
          <div className="self-center lg:max-w-[1440px]">
            <div className="lg:flex lg:flex-grow-0 lg:flex-row lg:space-x-[143px]">
              <div className="lg:flex lg:flex-col">
                <h1 className="mb-5 max-w-[270px] font-DMSans text-[36px] leading-[40px] tracking-[-0.5px] md:max-w-[400px] lg:mb-6 lg:w-[464px] lg:max-w-[464px] lg:whitespace-pre-wrap lg:text-[56px] lg:leading-[64px]">
                  Vaar vandaag nog in een Vloto deelsloep
                </h1>

                <p className="mb-8 max-w-[350px] text-[#494949] lg:mb-[50px] lg:w-[335px] lg:max-w-[335px] lg:text-[#757474]">
                  Onze sloepen zijn 100% elektrisch
                </p>

                <div className="mb-[50px] max-w-[166px]">
                  <Button
                    title={"Download app"}
                    variant={"solid"}
                    onClick={setShowAppModal}
                  />
                </div>
              </div>
              <div className="flex  md:h-[400px] md:w-[513px] md:self-center  lg:h-[538px] lg:w-[513px]">
                <img
                  src={VlotoBootImageMobile}
                  alt="Vloto Boten"
                  className="h-full w-full md:rounded-[20px] md:object-cover "
                />
              </div>
            </div>
          </div>
        </div>
      </InView>
    );
  }
);
