import React, { useContext, useEffect, useState } from "react";
import {
  faqBoatCategories,
  faqCategories,
  faqTaxiCategories,
  faqs,
} from "../../utils/faqs";
import { useLocation } from "react-router-dom";
import { Footer } from "../../components/Footer";
import FaqItem from "../../components/FaqItem";
import { Header } from "../../components/NewHeader";

const FaqPage = () => {
  const [selectedCategory, setSelectedCategory] = useState(faqCategories.basic);
  const [faqItems, setFaqItems] = useState(faqs);
  const [selectedType, setSelectedType] = useState("car");

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const selectFaq = (id) => {
    const newFaqs = faqItems.map((faq) => {
      if (faq.id === id) {
        if (faq.open === true) {
          return { ...faq, open: false };
        }

        return { ...faq, open: true };
      }

      if (faq.id !== id) {
        return { ...faq, open: false };
      }

      return faq;
    });

    setFaqItems(newFaqs);
  };
  const [isHeaderOpen, setIsHeaderOpen] = useState(false);

  return (
    <>
      <Header
        headerBackgroundColor={"#ffffff"}
        showSectionHeader={false}
        isHeaderOpen={isHeaderOpen}
        setIsHeaderOpen={setIsHeaderOpen}
      />
      <div className="min-h-screen bg-[#FAFAFA]">
        <div className="flex flex-col items-center pt-[60px]">
          <div className="flex flex-col items-center px-5">
            <h2 className="text-2xl !leading-[54px] text-black md:mb-3 md:text-32 lg:text-44 ">
              Helpcentrum
            </h2>

            <p className="mb-[46px] text-center !leading-[32px] text-darkGrey md:w-full  md:text-lg lg:text-xl">
              Hoe kunnen we je helpen?
            </p>
          </div>

          <div className="mb-8 flex w-full flex-col   px-5 md:px-10 lg:max-w-[900px] lg:px-0">
            <div className=" mb-2 flex items-center space-x-[10px]">
              <div
                className="relative cursor-pointer"
                onClick={() => setSelectedType("car")}
              >
                <p
                  className={`w-[80px] cursor-pointer text-center font-DMSans text-[20px] font-medium leading-[28px] tracking-[-0.5px] ${
                    selectedType === "car" ? "text-black" : "text-[#757474]"
                  }`}
                >
                  Auto's
                </p>
                {selectedType === "car" && (
                  <div className="absolute -bottom-[9px] h-[2px] w-full bg-black"></div>
                )}
              </div>

              <div
                onClick={() => setSelectedType("boat")}
                className="relative cursor-pointer"
              >
                <p
                  className={`w-[80px] cursor-pointer text-center font-DMSans text-[20px] font-medium leading-[28px] tracking-[-0.5px] ${
                    selectedType === "boat" ? "text-black" : "text-[#757474]"
                  }`}
                >
                  Boten
                </p>
                {selectedType === "boat" && (
                  <div className="absolute -bottom-[9px] h-[2px] w-full bg-black"></div>
                )}
              </div>

              <div
                onClick={() => setSelectedType("taxi")}
                className="relative cursor-pointer"
              >
                <p
                  className={`w-[80px] cursor-pointer text-center font-DMSans text-[20px] font-medium leading-[28px] tracking-[-0.5px] ${
                    selectedType === "taxi" ? "text-black" : "text-[#757474]"
                  }`}
                >
                  VlotoTaxi
                </p>
                {selectedType === "taxi" && (
                  <div className="absolute -bottom-[9px] h-[2px] w-full bg-black"></div>
                )}
              </div>
            </div>
            <div className="h-[1px] w-full bg-[#E8E8E8]"></div>
          </div>

          <div className="flex items-center">
            <div className="flex w-screen flex-row space-x-2 overflow-x-scroll px-5 scrollbar-hide md:space-x-5 md:px-10 lg:max-w-[900px] lg:justify-start lg:px-0 ">
              {Object.values(
                selectedType === "car"
                  ? faqCategories
                  : selectedType === "taxi"
                  ? faqTaxiCategories
                  : faqBoatCategories
              ).map((faqCategory, index) => (
                <div
                  key={index}
                  onClick={() => setSelectedCategory(faqCategory)}
                  className={`border-[1px] py-[10px] px-5 hover:cursor-pointer md:py-3 md:px-6 md:text-lg ${
                    selectedCategory === faqCategory
                      ? "text-white"
                      : "text-lightBlack"
                  }  rounded-[10px] ${
                    selectedCategory === faqCategory ? "bg-vlotoGreen" : "white"
                  } `}
                >
                  {faqCategory}
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="mb-[80px] mt-6 flex w-screen flex-col items-center space-y-5 self-center px-5 md:px-10 lg:mt-10">
          {faqItems
            .filter((faq) => faq.type === selectedType)
            .filter((faqFilter) => faqFilter.category === selectedCategory)
            .map((faqItem) => (
              <FaqItem
                key={faqItem.id}
                onClick={() => selectFaq(faqItem.id)}
                faq={faqItem}
              />
            ))}
        </div>
      </div>
      <Footer />
    </>
  );
};
export default FaqPage;
