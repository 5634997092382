import React, { useEffect, useRef, useState } from "react";
import { scrollToSection } from "../../pages/Boats/BoatPage";
import { BoatSectionTag } from "./BoatSectionTag";
import { useLocation } from "react-router-dom";

export const CarSectionHeader = ({
  currentlyInView,
  showHeaderSectionBorder,
  homeRef,
  benefitsRef,
  ourCarsRef,
  ourAppRef,
  priceRef,
  reviewRef,
  communitiesRef,
  howItWorksRef,
  headerBackgroundColor,
}) => {
  const location = useLocation();

  const sections = [
    {
      id: 1,
      name: "Home",
      ref: homeRef,
    },
    {
      id: 2,
      name: "Voordelen",
      ref: benefitsRef,
    },
    {
      id: 3,
      name: "Reviews",
      ref: reviewRef,
    },
    {
      id: 4,
      name: `Onze auto's`,
      ref: ourCarsRef,
    },
    {
      id: 5,
      name: "Tarieven",
      ref: priceRef,
    },
    {
      id: 6,
      name: "Onze app",
      ref: ourAppRef,
    },
  ];

  const filteredSections =
    location.pathname === "/"
      ? sections
      : sections.filter((section) => section.name !== "Communities");

  const scrollRef = useRef(null);

  const [currentIndex, setCurrentIndex] = useState(1);
  const [scrollTo, setScrollTo] = useState(null);

  useEffect(() => {
    const newIndex = sections.find(
      (section) => section.name === currentlyInView
    );

    setCurrentIndex(newIndex.id);
  }, [currentlyInView]);

  useEffect(() => {
    if (!scrollTo) {
      if (currentIndex > 4) {
        scrollRef.current.scrollTo({ left: 300, behavior: "smooth" });
      } else if (currentIndex <= 4) {
        scrollRef.current.scrollTo({ left: 0, behavior: "smooth" });
      }
    } else {
      if (
        scrollTo === "Tarieven" ||
        scrollTo === "Communities" ||
        scrollTo === "Reviews" ||
        scrollTo === "Hoe werkt het"
      ) {
        scrollRef.current.scrollTo({ left: 300, behavior: "smooth" });
      } else {
        scrollRef.current.scrollTo({ left: 0, behavior: "smooth" });
      }
      setTimeout(() => {
        setScrollTo(null);
      }, 500);
    }
  }, [currentlyInView, currentIndex]);
  return (
    <div
      className={`absolute flex h-11  w-full snap-x snap-mandatory snap-center items-center  space-x-2  ${
        headerBackgroundColor ? "bg-white" : "bg-transparent"
      } px-5  ${
        showHeaderSectionBorder && "border-b-[1px] border-card shadow-header"
      }`}
    >
      <div
        ref={scrollRef}
        className="-mx-5 flex snap-x snap-center space-x-2 overflow-x-scroll px-5 scrollbar-hide lg:mx-auto"
      >
        {filteredSections.map((section) => (
          <div key={section.id}>
            <BoatSectionTag
              section={section.name}
              currentlyInView={currentlyInView === section.name}
              onClick={() => {
                setScrollTo(section.name);
                scrollToSection(section.ref);
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
