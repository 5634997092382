import React from "react";
import { ReactComponent as Check } from "../../assets/icons/green-check.svg";

const NewUspTag = ({ text }) => {
  return (
    <div className="flex space-x-[6px] ">
      <div className="h-[24px] w-[24px]">
        <Check />
      </div>

      <p className="text-[14px] leading-[22px] text-[#D6D9D5]">{text}</p>
    </div>
  );
};

export default NewUspTag;
