import React from "react";
import ScrollLayout from "../../layouts/ScrollLayout";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";

const TermsPage = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    // <ScrollLayout showFooter={true}>
    <>
      <Header headerBackgroundColor={true} />
      <div className="p-6">
        <h1 className="text-lg">ALGEMENE VOORWAARDEN VLOTO B.V.</h1>

        <div className="">
          <h6 className="text-base mb-3 my-6 ">ARTIKEL 1. | DEFINITIES</h6>

          <p className="mb-3">
            In deze algemene voorwaarden worden de volgende termen, steeds met
            hoofdletter beginnend, in de navolgende betekenis gebruikt.
          </p>

          <p className="mb-3">
            1. Vloto: VlotoB.V., de gebruiker van deze algemene voorwaarden,
            gevestigd aan Kruitpad 16,1398 CP te Muiden, ingeschreven in het
            Handelsregister onder KvK-nummer 67630367.
          </p>

          <p className="mb-3">
            2. Gebruiker: eenieder met wie Vloto een Abonnement afsluit of
            beoogt af te sluiten.
          </p>

          <p className="mb-3">
            3. Partijen: Vloto en de Gebruiker gezamenlijk.
          </p>

          <p className="mb-3">
            4. Abonnement: de overeenkomst tussen Partijen die voor onbepaalde
            tijd wordt aangegaan en in het kader waar van de Gebruiker een Auto
            in gebruik kan nemen.
          </p>

          <p className="mb-3">
            5. Auto: iedere in het kader van het Abonnement door Vloto aan de
            Gebruiker ter beschikking te stellen en aan Vloto in eigendom
            toebehorende personenauto, inclusief alle eventuele bijbehoren.
          </p>

          <p className="mb-3">
            6. Schriftelijk: communicatie op schrift, communicatie per e-mail of
            enige andere wijze van communicatie die met het oog op de stand der
            techniek en de in het maatschappelijk verkeer geldende opvattingen
            hiermee gelijk kan worden gesteld.
          </p>
        </div>

        <div>
          <h6 className="text-base mb-3 my-6 ">
            ARTIKEL 2. | ALGEMENE BEPALINGEN
          </h6>

          <p className="mb-3">
            1. Deze algemene voorwaarden zijn van toepassing op elk aanbod van
            Vloto tot het aangaan van een Abonnement en ieder Abonnement als
            zodanig.
          </p>

          <p className="mb-3">
            2. Van het bepaalde in deze algemene voorwaarden kan uitsluitend
            uitdrukkelijk en Schriftelijk worden afgeweken. Indien en voor zover
            hetgeen Partijen uitdrukkelijk en Schriftelijk zijn overeengekomen,
            afwijkt van het bepaalde in deze algemene voorwaarden, geldt hetgeen
            Partijen uitdrukkelijk en Schriftelijk zijn overeengekomen.
          </p>

          <p className="mb-3">
            3. Vernietiging of nietigheid van een of meer van de bepalingen uit
            deze algemene voorwaard en of het Abonnement als zodanig, laat de
            geldigheid van de overige bedingen onverlet. In een voorkomend geval
            zijn Partijen verplicht in onderling overleg te treden teneinde een
            vervangende regeling te treffen ten aanzien van het aangetaste
            beding. Daarbij wordt zoveel mogelijk het doel en de strekking van
            de oorspronkelijke bepaling in acht genomen.
          </p>
        </div>

        <div>
          <h6 className="text-base mb-3 my-6 ">
            ARTIKEL 3. | AANBOD EN TOTSTANDKOMING VAN HET ABONNEMENT
          </h6>

          <p className="mb-3">
            1. Elk aanbod van Vloto tot het aangaan van een Abonnement is
            vrijblijvend. Vloto is nimmer verplicht een Abonnement met de
            Gebruiker aan te gaan en kan specifieke Gebruikers zonder opgave van
            redenen weigeren.
          </p>

          <p className="mb-3">
            2. Onverminderd het bepaalde in het vorige lid stelt Vloto in elk
            geval als voorwaarde voor totstandkoming van een Abonnement dat de
            Gebruiker over een geldig rijbewijs B beschikt.
          </p>

          <p className="mb-3">
            3. Een Abonnement komt, onverminderd het bepaalde in lid 1 en 2, tot
            stand op het moment dat de Gebruiker het aanbod van Vloto op de
            daartoe in de Vloto-app of op de website van Vloto aangegeven wijze
            heeft aanvaard en de Gebruiker heeft voldaan aan alle voorwaarden
            die uitdrukkelijk in het aanbod zijn vermeld. Vervolgens zal Vloto
            de totstandkoming van het Abonnement, onverminderd het bepaalde in
            lid 1 en 2, zo spoedig mogelijk per e-mail bevestigen.
          </p>
        </div>

        <div>
          <h6 className="text-base mb-3 my-6 ">
            ARTIKEL 4. | HERROEPINGSRECHT
          </h6>

          <p className="mb-3">
            1. Omdat een Abonnement middels een georganiseerd systeem voor
            contractsluiting op afstand wordt gesloten (via de Vloto-app of
            website van Vloto), is de Gebruiker gerechtigd het Abonnement tot 14
            dagen na totstandkoming daarvan, zonder opgave van redenen te
            herroepen.
          </p>

          <p className="mb-3">
            2. Nakoming van het Abonnement, binnen de bedenktijd als bedoeld in
            het vorige lid, geschiedt uitsluitend op uitdrukkelijk verzoek van
            de Gebruiker.
          </p>

          <p className="mb-3">
            3. De Gebruiker kan van het herroepingsrecht gebruik maken door
            daartoe per e-mail of door gebruikmaking van het door Vloto
            aangeboden modelformulier voor herroeping een verzoek in te dienen
            bij Vloto. Zodra Vloto in kennis is gesteld van het voornemen van de
            Gebruiker om het Abonnement te herroepen en indien is voldaan aan de
            voorwaarden van dit artikel, zal Vloto de herroeping zo spoedig
            mogelijk per e-mail bevestigen.
          </p>

          <p className="mb-3">
            4. Bij uitoefening van het herroepingsrecht na een verzoek van de
            Gebruikerovereenkomstig lid 2 is de Gebruiker Vloto een bedrag
            verschuldigd naar evenredigheid van het aantal dagen dat de
            Gebruiker van de dienstverlening van Vloto gebruik kon maken.
          </p>

          <p className="mb-3">
            5. Vloto zal de eventueel reeds van de Gebruiker ontvangen betaling,
            minus het eventuele evenredige bedrag als bedoeld in lid 4, zo
            spoedig mogelijk, doch uiterlijk binnen veertien dagen na ontbinding
            van het Abonnement aan de Gebruiker terugbetalen.
          </p>
        </div>

        <div>
          <h6 className="text-base mb-3 my-6 ">
            ARTIKEL 5. | INHOUD VAN HET ABONNEMENT
          </h6>

          <p className="mb-3">
            1. De Gebruiker kan gedurende de looptijd van het Abonnement Auto’s
            reserveren via de Vloto-app. Auto’s kunnen worden gereserveerd onder
            voorbehoud van voldoende beschikbaarheid daarvan op de door de
            Gebruiker gewenste locatie en tijd.
          </p>

          <p className="mb-3">
            2. Bij de reservering geeft de Gebruiker een begin- en eindtijd van
            de reservering op. Een reservering is pas definitief vanaf het
            moment dat Vloto de reservering heeft bevestigd.
          </p>

          <p className="mb-3">
            3. In geval na bevestiging van de reservering sprake blijkt te zijn
            van een calamiteit of bijzondere omstandigheid waardoor de Auto toch
            niet aan de Gebruiker beschikbaar kan worden gesteld c.q.
            redelijkerwijs niet van Vloto kan worden gevergd de Auto aan de
            Gebruiker beschikbaar te stellen, zoals in geval van schade,
            onderhoud of reparatie van de Auto, kan Vloto de reservering
            ongedaan maken zonder dat de Gebruiker aanspraak maakt op enige
            compensatie.
          </p>

          <p className="mb-3">
            4. De gereserveerde Auto kan tussen de overeengekomen begin- en
            eindtijd van de reservering door de Gebruiker in gebruik worden
            genomen. Bij aanvang dient de Gebruiker de reservering te starten en
            bij afloop dient de Gebruiker de reservering te stoppen. De Auto is
            beschikbaar op de in de Vloto-app aangegeven locatie.
          </p>

          <p className="mb-3">
            5. De Auto dient middels de Vloto-app te worden geopend, gestart en
            gesloten. Alle Auto’s zijn elektrisch en voorzien van een laadpas.
            Indien nodig dient de Gebruiker de Auto te laden door middel van
            deze laadpas.
          </p>

          <p className="mb-3">
            6. De kosten voor de Gebruiker uit hoofde van het Abonnement bestaan
            uit een vast maandelijks abonnementstarief, een uurtarief berekend
            over de periode tussen het starten en stoppen van de reservering
            zoals bedoeld in lid 4 en een bedrag per gereden kilometer.
          </p>

          <p className="mb-3">
            7.Reserveringen stoppen niet automatisch, ook niet indien de
            overeengekomen eindtijd van de reservering is verstreken. Het
            uurtarief als bedoeld in het vorige lid blijft lopen zolang de
            reservering niet is gestopt. Stoppen van een reservering kan
            uitsluitend op de terugbrenglocatie als bedoeld in het volgende lid.
            Stoppen van een reservering kan ook voordat de overeengekomen
            eindtijd van de reservering is verstreken.
          </p>

          <p className="mb-3">
            8. De Auto dient uiterlijk op de eindtijd van de reservering te zijn
            teruggebracht op de locatie waar de Auto door de Gebruiker in
            gebruik is genomen, tenzij uitdrukkelijk door Vloto is aangegeven
            dat de Auto ook op (een) andere locatie(s) kan worden teruggebracht.
          </p>

          <p className="mb-3">
            9. Indien de Auto later dan de overeengekomen eindtijd wordt
            teruggebracht, wordt voor elk uur waarmee de eindtijd is
            overschreden, € 30,- boete aan de Gebruiker in rekening gebracht.
          </p>

          <p className="mb-3">
            10. Indien de Gebruiker in gebreke is met de tijdige teruglevering
            van een Auto, is Vloto gerechtigd om de Auto onmiddellijk terug te
            (doen) nemen. De Gebruiker dient daaraan zijn volledige medewerking
            te verlenen. Alle kosten die Vloto als gevolg van het zelf (doen)
            terugnemen van een Auto moet maken om die Auto weer in zijn bezit te
            krijgen, komen voor rekening van de Gebruiker.
          </p>

          <p className="mb-3">
            11. Het bepaalde in het vorige lid geldt niet indien en voor zover
            zulks gelet op de omstandigheden van het geval onredelijk is,
            bijvoorbeeld omdat de Auto defect is geraakt en de Gebruiker
            daardoor niet in staat was om de Auto tijdig terug te leveren.
          </p>
        </div>

        <div>
          <h6 className="text-base mb-3 my-6 ">
            ARTIKEL 6. | DUUR EN OPZEGGING VAN HET ABONNEMENT
          </h6>

          <p className="mb-3">
            Het Abonnement wordt aangegaan voor onbepaalde tijd en eindigt door
            opzegging. Opzegging van het Abonnement door de Gebruiker dient op
            de daartoe door Vloto aangewezen wijze te geschieden. Het Abonnement
            eindigt op de laatste dag van de kalendermaand waarin de opzegging
            heeft plaatsgevonden.
          </p>
        </div>

        <div>
          <h6 className="text-base mb-3 my-6 ">
            ARTIKEL 7. | GEBRUIK VAN DE AUTO{" "}
          </h6>

          <p className="mb-3">
            1. Uitsluitend de persoon op wiens naam het Abonnement is aangegaan
            en personen binnen diens huishouden die beschikken over een geldig
            rijbewijs B, zijn gerechtigd om de Auto te besturen. Indien de
            Gebruiker het besturen van de Auto overlaat aan een andere persoon
            binnen zijn huishouden, geschiedt dit onder verantwoordelijkheid van
            de Gebruiker. Het laten besturen van de Auto door een andere persoon
            binnen het huishouden van de Gebruiker, geldt voor toepassing van
            het bepaalde in deze algemene voorwaarden als besturen van de Auto
            door de Gebruiker zelf.
          </p>

          <p className="mb-3">
            2. De Gebruiker zal als een goed gebruiker voor de Auto zorgdragen.
            Het is de Gebruiker niet toegestaan om een Auto te gebruiken op een
            circuit of voor wedstrijden. Voorts is het de Gebruiker niet
            toegestaan om een Auto te gebruiken op een terrein waarvoor die Auto
            redelijkerwijs niet geschikt is, dan wel waar is aangegeven dat het
            betreden daarvan op eigen risico is.
          </p>

          <p className="mb-3">
            3. De Gebruiker mag een Auto niet besturen indien hij in zo danige
            geestelijke of lichamelijke toestand verkeert dat besturen van de
            Auto door hem volgens de wet niet is toegestaan dan wel de Gebruiker
            daartoe kennelijk niet in staat is.
          </p>

          <p className="mb-3">
            4. De Gebruiker dient tijdens het rijden roekeloos rijgedrag te
            vermijden en de verkeersregels in acht te nemen.
          </p>

          <p className="mb-3">5. Roken in de Auto is niet toegestaan.</p>

          <p className="mb-3">
            6. Indien Vloto aan de Gebruiker omtrent het gebruik van een Auto
            specifieke aanwijzingen verstrekt, is de Gebruiker gehouden deze
            aanwijzingen op te volgen.
          </p>

          <p className="mb-3">
            7. De Gebruiker dient zorgvuldig om te gaan met alle met een Auto
            meegeleverde bijbehoren, zoals het kentekenbewijs en andere
            documenten.
          </p>

          <p className="mb-3">
            8. De aan het gebruik van een Auto verbonden kosten, zoals tolgelden
            en parkeerkosten, zijn voor rekening van de Gebruiker.
          </p>

          <p className="mb-3">
            9. Alle sancties die in verband met het gebruik van een Auto tijdens
            de periode dat een Auto ter beschikking van de Gebruiker staat, van
            overheidswege worden opgelegd, komen voor rekening van de Gebruiker,
            tenzij deze sancties het gevolg zijn van gebreken van de Auto die
            bij beschikbaarstelling daarvan aan de Gebruiker, reeds aanwezig
            waren, dan wel anderszins het gevolg zijn van omstandigheden die
            redelijkerwijs voor risico van Vloto komen.
          </p>

          <p className="mb-3">
            10. Indien Vloto wordt aangesproken voor het betalen van sancties
            als bedoeld in het vorige lid, waarvoor de Gebruiker aansprakelijk
            is, stelt de Gebruiker Vloto op diens eerste verzoek onmiddellijk
            schadeloos.
          </p>

          <p className="mb-3">
            11. Het is de Gebruiker niet toegestaan om een Auto op een plaats
            achter te laten zonder de Auto behoorlijk te vergrendelen. Voorts
            dient de Gebruiker te vermijden dat de Auto wordt blootgesteld aan
            andere dan normale risico’s van diefstal, vermissing en beschadiging
            daarvan.
          </p>

          <p className="mb-3">
            12. De Gebruiker doet Vloto zowel tijdens als na de periode dat een
            Auto ter beschikking van de Gebruiker staat onverwijld mededeling
            van alle feiten en omstandigheden betreffende die Auto waarvan de
            Gebruiker redelijkerwijs behoort te begrijpen dat deze informatie
            voor Vloto relevant is, zoals omtrent beslaglegging op de Auto. De
            Gebruiker dient in geval van beslaglegging de deurwaarder of
            beslaglegger onverwijld mededeling te doen van het feit dat de Auto
            eigendom is van Vloto. Voorts dient de Gebruiker Vloto onverwijld
            mededeling te doen van de beslaglegging.
          </p>
        </div>

        <div>
          <h6 className="text-base mb-3 my-6 ">
            ARTIKEL 8. | DIVERSE BEPALINGEN
          </h6>

          <p className="mb-3">
            1. De Gebruiker dient de Auto, onverminderd het bepaalde in de
            volgende leden, in dezelfde staat weer terug te leveren als waarin
            de Auto door hem in gebruik is genomen, in het bijzonder door de
            Auto zonder achtergebleven spullen weer aan Vloto beschikbaar te
            stellen. Eventuele schade ontstaan tijdens de periode dat de Auto
            ter beschikking van de Gebruiker stond, dient zo spoedig mogelijk
            aan Vloto te worden gemeld. Op verzoek van Vloto dient de Gebruiker
            mee te werken aan Schriftelijke vastlegging van de schade. Indien de
            Auto in extreem bevuilde staat wordt teruggeleverd of indien in de
            Auto is gerookt, wordt een boete van € 500,- aan de Gebruiker in
            rekening gebracht. Bovendien worden in dat geval schoonmaakkosten
            aan de Gebruiker in rekening gebracht volgens de gebruikelijke
            daarvoor door Vloto gehanteerde tarieven.
          </p>

          <p className="mb-3">
            2. In geval sprake is van beschadiging aan een Auto veroorzaakt door
            een derde of diefstal of vermissing van een Auto,dient de Gebruiker
            daarvan onmiddellijk aangifte te doen bij de politie en het
            verkregen aangifteformulier onmiddellijk aan Vloto te verstrekken.
            De Gebruiker dient zich bij de politie van alle erkenning van
            schuld, in welke vorm dan ook, te onthouden.
          </p>

          <p className="mb-3">
            3. Het is niet toegestaan om veranderingen aan een Auto aan te
            brengen, bij gebreke waarvan de schade en eventuele kosten van
            herstel voor rekening van de Gebruiker komen.
          </p>

          <p className="mb-3">
            4. In geval gedurende de periode dat een Auto ter beschikking van de
            Gebruiker staat, sprake is van een voor de Gebruiker kenbaar gebrek
            of van voor de Gebruiker kenbare schade aan die Auto, is het de
            Gebruiker niet toegestaan om de Auto te gebruiken indien dat kan
            leiden tot verergering van het gebrek of de schade, dan wel risico’s
            voor de verkeersveiligheid met zich kan mee meebrengen.
          </p>

          <p className="mb-3">
            5. In geval van pech of een storing waardoor verder rijden
            onmogelijk of onverantwoord is, dient Vloto daarvan onmiddellijk
            telefonisch op de hoogte worden gesteld. In die gevallen is Vloto
            nimmer verplicht een vervangende Auto aan de Gebruiker beschikbaar
            te stellen.
          </p>

          <p className="mb-3">
            6. Gehele of gedeeltelijke afstand of overdracht van gebruik van een
            Auto om niet of onder bezwarende titel, of anderszins, is niet
            toegestaan, daaronder mede begrepen verhuren en uitlenen van de Auto
            aan anderen dan bedoeld in artikel 7.1. Voorts is het niet
            toegestaan de Auto middels andere voertuigen te transporteren.
            Onverminderd het bepaalde in het voorgaande van dit lid, draagt de
            Gebruiker in de verhouding tot Vloto in elk geval de verantwoording
            en het risico voor gedragingen van diegene(n) aan wie de Gebruiker,
            onverminderd het bepaalde in artikel 7.1, de feitelijke macht over
            een Auto geheel of gedeeltelijk mocht hebben overgedragen of
            overgelaten.
          </p>

          <p className="mb-3">
            7. Het is de Gebruiker niet toegestaan ten aanzien van een Auto zelf
            herstel of onderhoud uit te voeren of daarvoor een derde in te
            schakelen, tenzij zulks geschiedt met voorafgaande Schriftelijke
            toestemming van Vloto.
          </p>

          <p className="mb-3">
            8. De Gebruiker machtigt Vloto, dan wel een door hem aangewezen
            derde, te allen tijde de plaats waar een Auto zich op dat moment
            bevindt te betreden en de Auto aan een onderzoek te onderwerpen. De
            Gebruiker is verplicht het betreden van de hiervoor bedoelde plaats
            mogelijk te maken en zijn medewerking te verlenen aan het door of
            namens Vloto in te stellen onderzoek.
          </p>
        </div>

        <div>
          <h6 className="text-base mb-3 my-6 ">
            ARTIKEL 9. | SCHADE EN AANSPRAKELIJKHEID
          </h6>

          <p className="mb-3">
            1. Beschadiging, vermissing, verduistering, diefstal, het verloren
            gaan van en defecten aan een Auto dienen na ontdekking daarvan door
            de Gebruiker, onverwijld aan Vloto te worden medegedeeld. De
            Gebruiker verleent Vloto, zijn verzekeraar en eventuele andere
            daartoe door Vloto aangewezen derden, op verzoek en onverwijld alle
            in redelijkheid door hen gewenste inlichtingen en bescheiden, onder
            meer doch niet uitsluitend wat betreft omstandigheden die tot de
            defecten, beschadiging, diefstal, etc. hebben geleid, alsook
            informatie ter verkrijging van schadevergoeding van derden.
          </p>

          <p className="mb-3">
            2. Onder schade van de Vloto wordt in het vervolg van dit artikel
            verstaan:
          </p>

          <p className="mb-3 ml-6">
            - de vermogensschade die Vloto lijdt ten gevolge van beschadiging of
            vermissing van een Auto of van onderdelen daarvan. Tot deze schade
            wordt onder meer gerekend de kosten van vervanging van (onderdelen
            van) een Auto en de derving van inkomsten;
          </p>
          <p className="mb-3 ml-6">
            - met of door een Auto aan persoon of goed toegebracht nadeel,
            waarvoor Vloto, de kentekenhouder of de
            aansprakelijkheidsverzekeraar ten aanzien van een Auto, jegens
            derden aansprakelijk is.
          </p>

          <p className="mb-3">
            3. De Gebruiker is in geval van schade van Vloto per schadegeval
            aansprakelijk tot het eigen risico als bedoeld in lid 4. Indiende
            schade van Vloto evenwel is ontstaan als gevolg van een doen of
            nalaten in strijd met de verplichtingen van de Gebruiker die uit het
            Abonnement (waaronder deze algemene voorwaarden mede begrepen)
            voortvloeien, is de Gebruiker volledig aansprakelijk voor de schade
            van Vloto, tenzij de Gebruiker bewijst dat dit doen of nalaten hem
            niet kan worden toegerekend of volledige vergoeding naar de
            maatstaven van redelijkheid en billijkheid onaanvaardbaar zou zijn.
          </p>

          <p className="mb-3">
            4. De Auto’s zijn WA+casco(All-risk) verzekerd. De verzekering biedt
            dekking binnen Europa. Het eigen risico van deze verzekering
            bedraagt € 500,-. In geval van schade waarvoor het eigen risico
            geldt, wordt het eigen risico aan de Gebruiker in rekening gebracht.
          </p>

          <p className="mb-3">
            5. Voor schade van Vloto die bestaat uit vermogensschade ten gevolge
            van met of door een Auto aan persoon of goed toegebracht nadeel,
            waarvoor Vloto, de kentekenhouder of de
            aansprakelijkheidsverzekeraar ten aanzien van de Auto, jegens derden
            aansprakelijk is, geldt het bepaalde in lid 3, tweede zin en verder
            slechts indien krachtens de verzekeringsovereenkomst die door Vloto
            ten aanzien van de Auto van toepassing is, geen dekking bestaat.
          </p>

          <p className="mb-3">
            6. Vloto is niet aansprakelijk voor schade aan door de Gebruiker
            middels een Auto vervoerde zaken.
          </p>
        </div>

        <div>
          <h6 className="text-base mb-3 my-6 ">
            ARTIKEL 10. | BETALINGSVOORWAARDEN
          </h6>

          <p className="mb-3">
            1. Betalingen geschieden maandelijks middels automatische incasso.
            Bij het aangaan van het Abonnement verstrekt de Gebruiker Vloto
            daartoe een machtiging.
          </p>

          <p className="mb-3">
            2. Eventuele schades en sancties waarvoor de Gebruiker aansprakelijk
            is, dienen te worden voldaan middels overboeking, binnen zeven dagen
            na factuurdatum.
          </p>

          <p className="mb-3">
            3. Behoudens tegen bewijs van de Gebruiker is de administratie van
            Vloto bepalend voor de vaststelling van het gereden aantal
            kilometers en de periode tussen het starten en stoppen van een
            reservering.
          </p>

          <p className="mb-3">
            4. Indien tijdige betaling achterwege blijft, treedt het verzuim van
            de Gebruiker van rechtswege in. Vanaf de dag dat het verzuim van de
            Gebruiker intreedt, is de Gebruiker over het opeisbare bedrag de dan
            geldende wettelijke rente verschuldigd.
          </p>

          <p className="mb-3">
            5. Alle redelijke kosten, zowel gerechtelijke, buitengerechtelijke
            als executiekosten, gemaakt ter verkrijging van door de Gebruiker
            verschuldigde bedragen, komen voor rekening van de Gebruiker.
          </p>
        </div>

        <div>
          <h6 className="text-base mb-3 my-6 ">
            ARTIKEL 11. | ONTBINDING VAN HET ABONNEMENT
          </h6>

          <p className="mb-3">
            1. Vloto is gerechtigd het Abonnement zonder nadere
            ingebrekestelling of rechterlijke tussenkomst te ontbinden,
            onverminderd zijn recht op vergoeding van kosten, schade en rente
            indien:
          </p>

          <p className="mb-3 ml-6">
            - de Gebruiker een of meer van zijn verplichtingen uit het
            Abonnement niet, niet tijdig of niet volledig nakomt, tenzij de
            tekortkoming de ontbinding van het Abonnement redelijkerwijs niet
            rechtvaardigt;
          </p>

          <p className="mb-3 ml-6">
            - Vloto van het bestaan van omstandigheden blijkt, die van dien aard
            zijn dat als Vloto hiervan vóór het sluiten van het Abonnement op de
            hoogte was geweest, hij het Abonnement niet was aangegaan.
          </p>

          <p className="mb-3">
            2. Vloto is niet aansprakelijk voor schade ten gevolge van
            ontbinding van het Abonnement op grond van het bepaalde in het
            vorige lid. De Gebruiker maakt in geval van ontbinding van het
            Abonnement geen aanspraak op restitutie of enige andere compensatie.
          </p>

          <p className="mb-3">
            3. Indien ontbinding van het Abonnement plaatsvindt terwijl de
            Gebruiker op dat moment in het bezit is van een Auto, is het de
            Gebruiker niet langer toegestaan om de Auto te besturen, tenzij
            Vloto in verband met de teruglevering van de Auto uitdrukkelijk
            anders voorschrijft.
          </p>
        </div>

        <div>
          <h6 className="text-base mb-3 my-6 ">ARTIKEL 12. | SLOTBEPALINGEN</h6>

          <p className="mb-3">
            1. De Gebruiker stemt er bij deze mee in dat Vloto tijdens de
            periode dat een Auto in het bezit is van de Gebruiker, door Vloto
            kan worden gevolgd middels locatie-tracking.
          </p>

          <p className="mb-3">
            2. Het recht op het instellen van een rechtsvordering of verweer in
            verband met de stelling dat de door Vloto verleende diensten niet
            aan het Abonnement beantwoorden, vervalt indien van de
            veronderstelde tekortkoming niet binnen zeven dagen na ontdekking
            daarvan, althans het redelijkerwijs kunnen ontdekken daarvan,
            Schriftelijk en gemotiveerd mededeling is gedaan aan Vloto. Indien
            een klacht niet in onderling overleg kan worden opgelost, kan de
            Gebruiker het geschil voorleggen aan de geschillencommissie via het
            ODR-platform (
            <a
              className="text-lightGreen"
              href="https://www.ec.europa.eu/consumers/odr/"
            >
              www.ec.europa.eu/consumers/odr/
            </a>
            ).
          </p>

          <p className="mb-3">
            3. Op elk Abonnement en alle daaruit tussen Partijen voortvloeiende
            rechtsverhoudingen is uitsluitend Nederlands recht van toepassing.
          </p>

          <p className="mb-3">
            4. Partijen zullen niet eerder een beroep doen op de rechter dan
            nadat zij zich optimaal hebben ingespannen om het geschil in
            onderling overleg te beslechten.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TermsPage;
