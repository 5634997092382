import React from "react";

export const SubscriptionCard = ({
  name,
  pricePerMonth,
  pricePerKm,
  freeHours,
  pricePerHour,
  recommended = false,
}) => {
  return (
    <div
      className={`relative flex w-full flex-col justify-start overflow-hidden rounded-[16px] outline-[1px] outline-[#858E81]  ${
        recommended ? "bg-[#1CA355]" : "bg-[#5D6957]"
      }  pl-6 pt-6 pb-[54px]`}
    >
      <p
        className={`${
          recommended ? "text-white" : "text-[#7BE2A6]"
        } mb-2 font-DMSans text-[14px] leading-[22px]`}
      >
        {name}
      </p>

      <div className="mb-[10px] flex flex-row items-end">
        <h3 className="mr-1 font-DMSans text-[46px] leading-[54px] text-white">
          €{pricePerMonth}
        </h3>
        <p className="pb-[6px] font-DMSans text-[20px] font-bold leading-[28px] tracking-[-0.5px] text-white">
          / maand
        </p>
      </div>

      <p className="mb-4 font-DMSans text-[20px] font-bold leading-[28px] text-white lg:w-[198px]">
        {freeHours
          ? `Elke maand eerste ${freeHours} uur gratis`
          : "Geen maandelijkse kosten"}
      </p>

      <div className="space-y-[2px]">
        <p className={`${!recommended ? "text-[#E8E8E8]" : "text-white"}`}>
          €{pricePerHour} per uur na vrije uren
        </p>
        <p className={`${!recommended ? "text-[#E8E8E8]" : "text-white"}`}>
          €{pricePerKm} per km
        </p>
      </div>

      {recommended && (
        <div className="absolute -top-[1px] right-0 rounded-bl-[20px] bg-[#E35D5D] px-5 py-[5px] ">
          <p className="font-DMSans text-[14px] leading-[22px] text-white">
            MEEST GEKOZEN
          </p>
        </div>
      )}
    </div>
  );
};
