import React from "react";
import Popup from "reactjs-popup";
import "../../../styles/styles.css";

import CloseButton from "../../../assets/icons/close.svg";
import CarGalleryContent from "../CarGalleryContent/CarGalleryContent";
import useLockBodyScroll from "../../../hooks/useLockBodyScroll";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

const CarGalleryModal = ({ car, openModal, setOpenModal }) => {
  useLockBodyScroll();

  const { width } = useWindowDimensions();

  return (
    <Popup
      nested={true}
      open={openModal}
      onClose={() => setOpenModal(false)}
      contentStyle={{
        maxHeight: "65vh",
        width: width < 1260 ? "90%" : 760,
        borderRadius: 10,
        padding: 0,
        borderWidth: 0,
        backgroundColor: "transparent",
      }}
    >
      <img
        onClick={() => setOpenModal(false)}
        className=" absolute bg-white rounded-full p-2 -top-14 right-0 hover:cursor-pointer"
        src={CloseButton}
        alt="Sluiten"
      />
      <CarGalleryContent car={car} />
    </Popup>
  );
};

export default CarGalleryModal;
