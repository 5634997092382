import React, { useState, useEffect, useContext } from "react";
import { Transition } from "@headlessui/react";
import logo from "../assets/logo/header-logo.svg";
import { useLocation, useNavigate } from "react-router-dom";
import Select, { components } from "react-select";
import { areas } from "../utils/areas";
import { UserContext } from "../App";

const { Option } = components;

const IconOption = (props) => (
  <Option {...props}>
    <div className="flex items-center">
      {props.data.label}
      {props.data.isNew && (
        <div className="ml-1 flex h-3 w-[30px] items-center justify-center rounded-[3px] bg-[#1CA556]">
          <p className="px-[2px] text-[8px] font-bold leading-[8px] text-white">
            NIEUW
          </p>
        </div>
      )}
    </div>
  </Option>
);

const options = areas.map((area) => ({
  value: area.name,
  label: area.displayName,
  isNew: area.new,
  slug: area.slug,
  headerTitle: area.headerTitle,
}));

export const Header = ({
  howItWorksRef,
  pricingRef,
  carsRef,
  faqRef,
  headerBackgroundColor,
  navigateToSection,
  setLocationState,
}) => {
  const { selectedCity, setSelectedCity } = useContext(UserContext);

  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const navigate = useNavigate();

  const navigateToHome = (section) => {
    navigate("/", { state: section });
  };

  const scrollToHowItWorks = () => {
    if (location.pathname !== "/" && location.pathname !== selectedCity.slug) {
      navigateToHome("howItWorks");
    } else {
      howItWorksRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      setIsOpen(false);
    }
  };

  const scrollToPrice = () => {
    if (location.pathname !== "/" && location.pathname !== selectedCity.slug) {
      navigateToHome("pricing");
    } else {
      pricingRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      setIsOpen(false);
    }
  };

  const scrollToCars = () => {
    if (location.pathname !== "/" && location.pathname !== selectedCity.slug) {
      navigateToHome("cars");
    } else {
      carsRef.current.scrollIntoView({ behavior: "smooth", block: "start" });

      setIsOpen(false);
    }
  };
  const scrollToFaq = () => {
    if (location.pathname !== "/" && location.pathname !== selectedCity.slug) {
      navigateToHome("faq");
    } else {
      faqRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
      setIsOpen(false);
    }
  };

  const scrollToTop = () => {
    if (location.pathname !== "/") {
      if (location.pathname === selectedCity.slug) {
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
      } else {
        navigateToHome();
      }
    } else {
      window.scroll({ top: 0, left: 0, behavior: "smooth" });
    }
  };

  const dropdownOnChange = (item) => {
    if (location.pathname !== item.slug) {
      const newCity = areas.find((area) => area.displayName === item.label);

      setSelectedCity(newCity);
      navigate(item.slug);
    }
  };

  useEffect(() => {
    const newCity = areas.find((area) => area.slug === location.pathname);

    if (!!newCity) {
      setSelectedCity(newCity);
    }
  }, [location.pathname]);

  return (
    <nav
      className={`${
        headerBackgroundColor && "bg-[#FAFAFA]"
      } sticky top-0 z-50 border-b-[1px] border-card`}
    >
      <div className="mx-auto h-[60px] pl-5 pr-6 md:h-[60px] lg:h-[80px] lg:px-[160px]">
        <div className="flex h-full justify-between ">
          <div className="flex w-full items-center justify-between">
            <div className="flex-shrink-0">
              <div className="hover:cursor-pointer" onClick={scrollToTop}>
                <img
                  src={logo}
                  alt="Logo"
                  className="h-5 md:h-6 lg:h-6 xl:h-7 "
                />
              </div>
            </div>

            {/* Desktop navbar */}
            <div className="hidden lg:flex ">
              <div className=" flex space-x-4 ">
                <button
                  onClick={scrollToCars}
                  className={`rounded-md  px-3 py-2 text-sm font-medium text-vlotoGreen decoration-lightGreen hover:cursor-pointer `}
                >
                  Onze auto's
                </button>

                <button
                  onClick={scrollToPrice}
                  className={`rounded-md  px-3 py-2 text-sm font-medium text-vlotoGreen decoration-lightGreen hover:cursor-pointer `}
                >
                  Tarieven
                </button>

                <button
                  onClick={scrollToHowItWorks}
                  className={`rounded-md  px-3 py-2 text-sm font-medium text-vlotoGreen decoration-lightGreen hover:cursor-pointer`}
                >
                  Hoe werkt het?
                </button>

                <button
                  onClick={scrollToFaq}
                  className={`rounded-md  px-3 py-2 text-sm font-medium text-vlotoGreen decoration-lightGreen  hover:cursor-pointer `}
                >
                  FAQ
                </button>
              </div>
            </div>
            <div className="flex items-center">
              <div className="hidden text-sm lg:flex">Locaties</div>

              <Select
                className="flex w-[109px] justify-end pr-2 md:pr-2 lg:pr-0"
                defaultValue={{
                  value: selectedCity.name,
                  label: selectedCity.headerTitle,
                  headerTitle: selectedCity.headerTitle,
                  isNew: selectedCity.new,
                  slug: selectedCity.slug,
                }}
                value={{
                  value: selectedCity.name,
                  label: selectedCity.headerTitle,
                  headerTitle: selectedCity.headerTitle,
                  isNew: selectedCity.new,
                  slug: selectedCity.slug,
                }}
                onChange={dropdownOnChange}
                options={options}
                components={{ Option: IconOption }}
                isSearchable={false}
                styles={{
                  indicatorSeparator: (baseStyles) => ({
                    ...baseStyles,
                    width: 0,
                  }),
                  dropdownIndicator: (baseStyles, state) => ({
                    ...baseStyles,
                    position: "absolute",
                    color: "#34432D",
                    right: -2,
                    // backgroundColor: "red",
                    transition: "all .2s ease",
                    transform: state.selectProps.menuIsOpen
                      ? "rotate(180deg)"
                      : null,
                  }),
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    backgroundColor: "#F4F4F4",
                    borderColor: "none",
                    boxShadow: "none",
                    borderWidth: 0,
                    borderRadius: 8,
                    width: 90,
                    fontSize: 14,

                    cursor: "pointer",
                  }),
                  valueContainer: (baseStyles) => ({
                    ...baseStyles,
                    width: 52,
                    textOverflow: "clip",
                    whiteSpace: "nowrap",
                  }),
                  option: (baseStyles) => ({
                    ...baseStyles,
                    color: "#494949",
                    cursor: "pointer",
                    width: 190,
                    backgroundColor: "white",
                  }),

                  menu: (baseStyles) => ({
                    ...baseStyles,
                    color: "#494949",
                    backgroundColor: "white",
                    width: 190,
                  }),
                  menuList: (baseStyles) => ({
                    ...baseStyles,
                    color: "#494949",
                    backgroundColor: "white",
                    borderColor: "red",
                    borderRadius: 8,
                  }),
                }}
              />
            </div>
          </div>

          {/* Mobile navbar */}
          <div className="-mr-2 flex lg:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              type="button"
              className={`inline-flex items-center justify-center rounded-md p-2 text-vlotoGreen hover:text-white`}
              aria-controls="mobile-menu"
              aria-expanded="false"
            >
              {!isOpen ? (
                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="black"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              ) : (
                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="black"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              )}
            </button>
          </div>
        </div>
      </div>

      <Transition
        show={isOpen}
        enter="transition ease-out duration-100 transform"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-75 transform"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        {(ref) => (
          <div className="lg:hidden" id="mobile-menu">
            <div ref={ref} className="space-y-1 px-2 pt-2 pb-3 sm:px-3">
              <button
                onClick={scrollToCars}
                className={`block  rounded-md px-3 py-2 text-base font-medium text-vlotoGreen decoration-lightGreen hover:cursor-pointer`}
              >
                Onze auto's
              </button>

              <button
                onClick={scrollToPrice}
                className={`block  rounded-md px-3 py-2 text-base font-medium text-vlotoGreen decoration-lightGreen hover:cursor-pointer`}
              >
                Tarieven
              </button>

              <button
                onClick={scrollToHowItWorks}
                className={`block  rounded-md px-3 py-2 text-base font-medium text-vlotoGreen decoration-lightGreen hover:cursor-pointer `}
              >
                Hoe werkt het?
              </button>

              <button
                onClick={scrollToFaq}
                className={`block  rounded-md px-3 py-2 text-base font-medium text-vlotoGreen decoration-lightGreen hover:cursor-pointer `}
              >
                FAQ
              </button>
            </div>
          </div>
        )}
      </Transition>
    </nav>
  );
};
