import React from "react";
import { InView } from "react-intersection-observer";
import BookingScreen from "../../assets/taxi/home-screen.png";
import { TaxiUspItem } from "./TaxiUspItem";
import { VlotoTaxiButton } from "./VlotoTaxiButton";

export const TaxiUspTwo = ({ setShowAppModal, setCurrentlyInView }) => {
  return (
    <InView
      as="div"
      threshold={0.8}
      // onChange={(inView) => inView && setCurrentlyInView("Voordelen")}
    >
      <div className="flex flex-col py-[80px] px-5  lg:py-[110px] lg:px-[160px]">
        <div className=" self-center   lg:flex lg:max-w-[1260px] lg:space-x-[144px]">
          <div className="flex-shrink-0 md:mb-10 md:h-[535px] md:w-[510px]">
            <img
              src={BookingScreen}
              alt="Boek je Vloto auto of boot"
              className="mb-6 w-full flex-shrink-0"
            />
          </div>

          <div>
            <div className="mb-8 space-y-3 md:mb-10 lg:mb-[50px] lg:space-y-4">
              <p className="w-[95%] font-DMSans text-2xl font-bold tracking-[-0.5px] md:mb-6 md:w-[330px] md:text-32 md:leading-[40px] lg:mb-8 lg:text-32">
                Volledige toegang met de Vloto
                <span className="text-[#1CA556]">Taxi</span> app
              </p>

              <TaxiUspItem
                text="Reserveer."
                description={"Direct of voor in de toekomst, wat jou uitkomt."}
              />

              <TaxiUspItem
                text="Ophalen en terugbrengen."
                description={"In de RAI Amsterdam."}
              />

              <TaxiUspItem
                text="Open en sluit"
                description={"de auto met de app."}
              />

              <TaxiUspItem
                text="Betaal"
                description={"alleen voor je gebruik."}
              />
            </div>
            <VlotoTaxiButton onClick={setShowAppModal} />
          </div>
        </div>
      </div>
    </InView>
  );
};
