import React from "react";
import { ReactComponent as Quote } from "../../assets/icons/quote.svg";

const ReviewCard = ({ name, feedback, type }) => {
  return (
    <div className="h-[300px] w-[240px] rounded-[17px]  border-[1px] border-[#E8E8E8] bg-[#F4F4F4] p-5 md:h-[280px] md:w-[260px] lg:w-[356px]">
      <Quote />

      <div className="mt-[18px]">
        {type !== "short" && <p className="font-bold">{name}</p>}

        {type === "short" && (
          <>
            <p>
              {name} heeft een boeking beëindigd en de volgende feedback
              achtergelaten:
            </p>

            <p className="mt-6 font-DMSans text-[32px] font-bold leading-[40px]">
              {feedback}
            </p>
          </>
        )}

        {type !== "short" && <p className="">{feedback}</p>}
      </div>
    </div>
  );
};

export default ReviewCard;
