import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "../../../styles/styles.css";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { isMobile } from "react-device-detect";

const CarGalleryContent = ({ car }) => {
  return (
    <Swiper
      lazy={true}
      style={{
        borderRadius: isMobile ? 10 : 20,
        "--swiper-navigation-size": "16px",
      }}
      slideToClickedSlide={true}
      className="w-full max-w-[760px]"
      slidesPerView={1}
      modules={[Pagination, Navigation]}
      pagination={{
        clickable: true,
      }}
      navigation={true}
    >
      {car?.galleryImages?.map((carImage, index) => (
        <LazyLoadComponent>
          <SwiperSlide key={index} style={{ borderRadius: 30 }}>
            <img
              src={carImage}
              alt={car?.name}
              className={`max-h-[70vh] object-cover  ${
                car?.name === "Polestar 2" && index === 0
                  ? "object-bottom"
                  : "object-center"
              }
              ${car?.name === "Polestar 2" && index === 3 && "object-left"}
              ${car?.name === "Polestar 2" && index === 4 && "object-left"}
              ${
                car?.name === "Audi e-tron 55 Quattro" &&
                index === 3 &&
                "object-left md:object-center"
              }
              rounded-t-[10px] h-[364px] md:h-[600px] lg:h-[750px] rounded-b-[10px] w-full mb-2`}
            />
          </SwiperSlide>
        </LazyLoadComponent>
      ))}
    </Swiper>
  );
};

export default CarGalleryContent;
