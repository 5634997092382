import { Header } from "../components/Header";
import { Footer } from "../components/Footer";

const ScrollLayout = ({
  children,
  showFooter,
  pricingRef,
  howItWorksRef,
  appRef,
  faqRef,
  sticky,
}) => {
  return (
    <div className=" bg-[#FAFAFA]">
      <div className="">
        <Header headerBackgroundColor={true} />
      </div>
      {children}
      <Footer sticky={sticky} showFooter={showFooter} />
    </div>
  );
};

export default ScrollLayout;
