import React, { forwardRef } from "react";
import ParkingArea from "../../assets/taxi/parking-area.png";
import TaxiCar from "../../assets/taxi/taxi-car.png";
import CancelReservation from "../../assets/boats/Illustrations/cancel-reservation.png";
import { TaxiUspOneCard } from "./TaxiUspOneCard";
import { InView } from "react-intersection-observer";
import useWindowDimensions from "../../hooks/useWindowDimensions";

export const TaxiUspOne = forwardRef(({ setCurrentlyInView }, ref) => {
  const { width } = useWindowDimensions();

  return (
    <InView
      ref={ref}
      as="div"
      threshold={width > 744 ? 0.6 : 0.2}
      onChange={(inView, entry) =>
        entry.isIntersecting && setCurrentlyInView("Voordelen")
      }
    >
      <div className="flex flex-col py-[60px] px-5 md:bg-[#FAFAFA] md:py-[80px] md:px-10 lg:px-[80px] lg:py-[110px] xl:px-[160px]">
        <div className="self-center md:max-w-[744px] lg:max-w-[1120px]">
          <h1 className=" mb-10 max-w-[304px] text-2xl leading-[32px] tracking-[-0.5px] md:max-w-[420px] md:text-32 md:leading-[40px] lg:w-[441px] lg:max-w-[441px] ">
            €5 per uur - Geen vaste kosten, verplichtingen, geheel ontzorgd
          </h1>

          <div className="grid grid-cols-1 gap-10 md:grid-cols-2 md:gap-5 lg:grid-cols-3 lg:gap-[60px]">
            <TaxiUspOneCard
              img={ParkingArea}
              alt="Ophalen en terugbrengen bij Amsterdam RAI."
              text="Ophalen en terugbrengen bij Amsterdam RAI. Makkelijk bereikmaar met OV"
            />
            <TaxiUspOneCard
              img={TaxiCar}
              alt={
                "100% elektrische, luxe auto's. Met blauwe kentekens en boordcomputer"
              }
              text="100% elektrische, luxe auto's. Met blauwe kentekens en boordcomputer"
            />
            <TaxiUspOneCard
              img={CancelReservation}
              alt="Maak gemakkelijk een reservering via de app. Huur alleen wanneer je de auto nodig hebt!"
              text="Maak gemakkelijk een reservering via de app. Huur alleen wanneer je de auto nodig hebt!"
            />
          </div>
        </div>
      </div>
    </InView>
  );
});
