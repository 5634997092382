import React, { useState } from "react";
import Popup from "reactjs-popup";
import useWindowDimensions from "../hooks/useWindowDimensions";
import FirstRideModal from "./FirstRideModal";
import { DownloadAppModal } from "./DownloadAppModal";
import { isAndroid, isIOS, isMobile } from "react-device-detect";

const DealLabel = () => {
  const [showModal, setShowModal] = useState(false);
  const [showAppModal, setShowAppModal] = useState(false);
  const { width } = useWindowDimensions();

  const showAppDownloadModal = () => {
    if (!isMobile) {
      setShowAppModal(true);
      return;
    }

    if (isAndroid) {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.vloto.vloto";
    } else if (isIOS) {
      window.location.href = "https://apps.apple.com/us/app/vloto/id1622367728";
    }
  };

  return (
    <>
      <Popup
        contentStyle={{
          backgroundColor: "transparent",
          border: "none",
          width: width > 1260 ? 1120 : 744,
        }}
        open={showAppModal}
        onClose={() => setShowAppModal(false)}
      >
        <DownloadAppModal setShowAppModal={setShowAppModal} />
      </Popup>

      <Popup
        contentStyle={{
          position: width >= 744 ? "relative" : "absolute",
          bottom: 0,
          marginLeft: width >= 744 ? undefined : -5,
          backgroundColor: "transparent",
          border: "none",
          width: width > 1260 ? 1120 : 611,
        }}
        open={showModal}
        onClose={() => setShowModal(false)}
      >
        <FirstRideModal
          showAppModal={showAppDownloadModal}
          closeModal={() => setShowModal(false)}
        />
      </Popup>

      <div
        onClick={() => setShowModal(true)}
        className="z-50 flex h-[32px] w-fit cursor-pointer flex-row items-center space-x-[6px] rounded-[40px] border-[1px] border-[#A7EBC4] bg-[#D3F5E1] py-[5px] pl-[6px] pr-[12px]"
      >
        <div className="rounded-[40px] bg-[#1CA556]">
          <p className="px-1 py-[6px] text-[8px] font-bold leading-[8px] text-white">
            DEAL
          </p>
        </div>
        <p className="text-[14px] leading-[22px]">
          Je eerste rit is altijd <b>gratis</b>
        </p>
      </div>
    </>
  );
};

export default DealLabel;
