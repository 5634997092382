import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";

const PrivacyPage = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <Header headerBackgroundColor={true} />
      <div className="min-h-screen bg-[#FAFAFA]">
        <div className="p-6">
          <p>
            Vloto B.V. protects your personal data and privacy. This privacy
            statement includes what personal information we collect and how we
            keep your information and use. This privacy statement applies to our
            website www.Vloto.nl (the Website) and the services we, Vloto B.V.,
            offer through our mobile applications (Apps). What personal
            information do we keep? Your personal information may be stored at
            different times, such as when you visit our Web site, create a
            personal account on our Apps, use our services, or contact us. We
            store information such as your name, address, telephone number,
            email address, and your payment information. We also store images of
            your driver's license, passport/id, and a selfie to verify it's you.
          </p>

          <br />

          <p>
            Before entering into an agreement with you, we would like to assess
            any potential payment risks. This will help us determine whether we
            will enter into an agreement with you and what terms we will apply.
            To this end, we will also provide your information to Economic Data
            Resources B.V. (EDR). Economic Data Resources B.V. (EDR) checks
            personal data and provides credit information reports with a risk
            profile. You can read information about the processing of personal
            data by Economic Data Resources B.V. (EDR) in their privacy
            statement{" "}
            <a
              className="text-lightGreen underline"
              href="https://www.edrcreditservices.nl/privacy-statement/"
              target="_blank"
            >
              here
            </a>
            . This document also explains how you can exercise your rights, such
            as the right to access, objection, rectification, and erasure.
          </p>

          <br />

          <p>
            To provide our services effectively and securely, we work with
            trusted third-party companies. These companies handle your personal
            data with care and will never sell your information to other third
            parties. The companies we work with include:
          </p>

          <ul className="list-inside list-disc text-[#494949]">
            <li>
              Mollie: Mollie handles payment processing for Vloto B.V. Your
              payment details are securely processed and stored by Mollie
              according to their privacy standards.
            </li>
            <li>
              Onfido: Onfido is responsible for identity verification, including
              the processing of your ID documents and selfie images. Onfido
              ensures that your data is handled securely and is not shared with
              unauthorized parties.
            </li>
          </ul>

          <br />

          <p>
            If you are 18 or younger, it is not possible to use our apps. This
            is due to the minimum age for obtaining a driving license in the
            Netherlands. We can also store non-personal data; such as your
            browser type, type of computer, and technical information on how you
            connect with our Site or Apps. Why do we keep your personal data? We
            store and process your personal information to comply with your
            request to use the service, to improve our services, and to protect
            and manage your personal account. Sometimes we will use your
            personal information to send you important information, such as a
            change in our terms and conditions and privacy statement. Without
            your consent, we will not share your data with others except when it
            is required in relation to services that may be run by authorized
            third parties.
          </p>

          <br />

          <p>
            You can sign up for our newsletter so that you are always up to date
            with the latest news and updates on our services and our company. If
            you do not want to receive newsletters anymore, you can unsubscribe
            using the 'unsubscribe' button at the bottom of each newsletter. How
            do we protect your personal information? We do everything to protect
            your personal data from loss, destruction, use, modification, or
            disclosure of your personal data by unauthorized persons. We do this
            with Secure Socket Layer (SSL). This protects your data from being
            seen by any unauthorized parties. How can I view my personal data,
            change or delete it? You can see your personal information, change
            or delete it by logging into your account in the Apps.
          </p>

          <br />

          <p>
            Cookies: We may use cookies to enhance your experience on our Web
            site. A cookie is a small piece of information that is stored on
            your computer when you visit our Website. With the use of cookies,
            our website can recognize you when you visit us again. Cookies help
            ensure that the site is fast, you can visit our website safely, and
            we can detect errors on our website. If you do not want to receive
            cookies, you can change your settings in your browser and delete
            cookies.
          </p>

          <br />

          <p>
            Please note: without cookies, our website will not work properly.
            Links: Our website may contain links to other websites. We are not
            responsible for the content or the privacy practices of these
            websites. We therefore advise you to always read the privacy
            statement of any website you visit. Changes to this privacy
            statement: We may occasionally update our privacy statement. If we
            make a major change to this privacy statement, we will post it on
            our website and we will send you an email. Questions? If you have
            questions or comments, please send us an email at info@vloto.nl.
            Vloto B.V., Kruitpad 16, 1398CP Muiden.
          </p>
        </div>
      </div>
      <Footer showFooter={true} sticky={false} />
    </>
  );
};

export default PrivacyPage;
