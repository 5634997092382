import { Transition } from "@headlessui/react";
import React, { useContext, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Select, { components } from "react-select";
import { UserContext } from "../App";
import ArrowRight from "../assets/icons/chevron-right-black.svg";
import logo from "../assets/logo/header-logo.svg";
import { areas } from "../utils/areas";
import { CarSectionHeader } from "./Boats/CarSectionHeader";
import { HeaderButton } from "./HeaderButton";
import { SmallNewLabel } from "./SmallNewLabel";

const { Option } = components;

const IconOption = (props) => (
  <Option {...props}>
    <div className="flex items-center">
      {props.data.label}
      {props.data.isNew && (
        <div className="ml-1 flex h-3 w-[30px] items-center justify-center rounded-[3px] bg-[#1CA556]">
          <p className="px-[2px] text-[8px] font-bold leading-[8px] text-white">
            NIEUW
          </p>
        </div>
      )}
    </div>
  </Option>
);

const options = areas
  .filter((area) => !area.isTaxi)
  .map((area) => ({
    value: area.name,
    label: area.displayName,
    isNew: area.new,
    slug: area.slug,
    headerTitle: area.headerTitle,
  }));

export const Header = ({
  isHeaderOpen,
  setIsHeaderOpen,
  currentlyInView,
  showHeaderSectionBorder,
  homeRef,
  benefitsRef,
  ourCarsRef,
  ourAppRef,
  priceRef,
  communitiesRef,
  headerBackgroundColor,
  howItWorksRef,
  showSectionHeader = true,
  reviewRef,
  showAppDownloadModal,
}) => {
  const { selectedCity, setSelectedCity, setSelectedVehicleType } =
    useContext(UserContext);

  const location = useLocation();

  const navigate = useNavigate();

  const navigateToHome = (section) => {
    navigate("/", { state: section });
  };

  const scrollToTop = () => {
    if (location.pathname !== "/boten") {
      if (location.pathname === selectedCity.slug) {
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
      } else {
        navigateToHome();
      }
    } else {
      window.scroll({ top: 0, left: 0, behavior: "smooth" });
    }
  };

  const dropdownOnChange = (item) => {
    if (location.pathname !== item.slug) {
      const newCity = areas.find((area) => area.displayName === item.label);

      setSelectedCity(newCity);
      navigate(item.slug);
    }
  };

  useEffect(() => {
    if (location.pathname.includes("/boten")) {
      setSelectedVehicleType("boat");
    } else {
      setSelectedVehicleType("car");
    }
    const newCity = areas.find((area) => area.slug === location.pathname);

    if (!!newCity) {
      setSelectedCity(newCity);
    }
  }, [location.pathname]);

  return (
    <div className=" sticky top-0 z-50 ">
      <nav
        className={` sticky top-0 z-50 border-b-[1px] ${
          isHeaderOpen ? "border-white" : "border-card"
        } ${!headerBackgroundColor ? "bg-transparent" : "bg-white"} `}
      >
        <div className="mx-auto h-[60px] pl-5  pr-6 lg:px-[80px] xl:max-w-[1440px] xl:px-[160px]">
          <div className="flex h-full justify-between ">
            <div className="flex w-full items-center justify-between">
              <div className="flex-shrink-0">
                <div
                  className="hover:cursor-pointer"
                  onClick={() =>
                    selectedCity?.name !== "Alle"
                      ? navigateToHome()
                      : scrollToTop()
                  }
                >
                  <img
                    src={logo}
                    alt="Logo"
                    className="h-5 md:h-6 lg:h-6 xl:h-7 "
                  />
                </div>
              </div>

              {selectedCity.name === "Alle" && (
                <div className="hidden lg:mx-auto lg:flex lg:space-x-8">
                  <Link to="/" className="">
                    <p>Auto's</p>
                  </Link>

                  <Link
                    to="/boten"
                    className="hidden lg:flex lg:items-center lg:space-x-1"
                  >
                    <p>Boten</p>
                  </Link>

                  <Link
                    to="/huurtaxi"
                    className="hidden lg:flex lg:items-center lg:space-x-1"
                  >
                    <p>Voor taxichauffeurs</p>
                    <SmallNewLabel />
                  </Link>

                  <Link to="/veelgestelde-vragen">
                    <p>FAQ</p>
                  </Link>
                </div>
              )}

              {/* Desktop navbar */}
              <div className="hidden lg:flex ">
                <div className=" flex space-x-4 "></div>
              </div>

              {selectedCity?.name === "Alle" && (
                <div className="flex items-center">
                  <div className="hidden text-sm lg:flex">Locaties</div>

                  <Select
                    className="flex w-[109px] justify-end pr-2 md:pr-2 lg:pr-0"
                    defaultValue={{
                      value: selectedCity.name,
                      label: selectedCity.headerTitle,
                      headerTitle: selectedCity.headerTitle,
                      isNew: selectedCity.new,
                      slug: selectedCity.slug,
                    }}
                    value={{
                      value: selectedCity.name,
                      label: selectedCity.headerTitle,
                      headerTitle: selectedCity.headerTitle,
                      isNew: selectedCity.new,
                      slug: selectedCity.slug,
                    }}
                    maxMenuHeight={1000}
                    onChange={dropdownOnChange}
                    options={options}
                    components={{ Option: IconOption }}
                    isSearchable={false}
                    styles={{
                      indicatorSeparator: (baseStyles) => ({
                        ...baseStyles,
                        width: 0,
                      }),
                      dropdownIndicator: (baseStyles, state) => ({
                        ...baseStyles,
                        position: "absolute",
                        color: "#34432D",
                        right: -2,
                        // backgroundColor: "red",
                        transition: "all .2s ease",
                        transform: state.selectProps.menuIsOpen
                          ? "rotate(180deg)"
                          : null,
                      }),
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        backgroundColor: "#F4F4F4",
                        borderColor: "none",
                        boxShadow: "none",
                        borderWidth: 0,
                        borderRadius: 8,
                        width: 90,
                        fontSize: 14,

                        cursor: "pointer",
                      }),
                      valueContainer: (baseStyles) => ({
                        ...baseStyles,
                        width: 52,
                        textOverflow: "clip",
                        whiteSpace: "nowrap",
                      }),
                      option: (baseStyles) => ({
                        ...baseStyles,
                        color: "#494949",
                        cursor: "pointer",
                        width: 200,
                        backgroundColor: "white",
                      }),

                      menu: (baseStyles) => ({
                        ...baseStyles,
                        color: "#494949",
                        backgroundColor: "white",
                        width: 200,
                      }),
                      menuList: (baseStyles) => ({
                        ...baseStyles,
                        color: "#494949",
                        backgroundColor: "white",
                        borderColor: "red",
                        borderRadius: 8,
                      }),
                    }}
                  />
                </div>
              )}
            </div>

            {/* Mobile navbar */}
            {selectedCity?.name === "Alle" && (
              <div className="-mr-2 flex lg:hidden">
                <button
                  onClick={() => setIsHeaderOpen(!isHeaderOpen)}
                  type="button"
                  className={`inline-flex items-center justify-center rounded-md p-2 text-vlotoGreen hover:text-white`}
                  aria-controls="mobile-menu"
                  aria-expanded="false"
                >
                  {!isHeaderOpen ? (
                    <svg
                      className="block h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="black"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4 6h16M4 12h16M4 18h16"
                      />
                    </svg>
                  ) : (
                    <svg
                      className="block h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="black"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  )}
                </button>
              </div>
            )}

            <div className="flex items-center">
              {selectedCity.name !== "Alle" && (
                <HeaderButton
                  onClick={showAppDownloadModal}
                  variant={"solid"}
                  title={"Download"}
                />
              )}
            </div>
          </div>
        </div>

        <Transition
          show={isHeaderOpen}
          enter="transition ease-out duration-100 transform"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-75 transform"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          {(ref) => (
            <div
              className="relative flex items-center justify-center lg:hidden"
              id="mobile-menu"
            >
              <div
                ref={ref}
                className="absolute top-0 flex w-[85%] max-w-[439px] flex-col items-start self-center rounded-[16px] border-[1px] border-card bg-white p-4 shadow-card"
              >
                <Link to="/" className="flex w-full justify-between px-4 py-3">
                  <p>Auto's</p>
                  <img
                    src={ArrowRight}
                    alt="Versturen"
                    className="text-black md:hidden"
                  />
                </Link>

                <div className="my-1 h-[1px] w-[90%] self-center bg-card"></div>

                <Link
                  onClick={() => {
                    location.pathname === "/boten" && setIsHeaderOpen(false);
                  }}
                  to="/boten"
                  className="flex w-full justify-between px-4 py-3"
                >
                  <div className="flex items-center space-x-2">
                    <p>Boten</p>
                  </div>

                  <img
                    src={ArrowRight}
                    alt="Versturen"
                    className="text-black md:hidden"
                  />
                </Link>

                <div className="my-1 h-[1px] w-[90%] self-center bg-card"></div>

                <Link
                  onClick={() => {
                    location.pathname === "/huurtaxi" && setIsHeaderOpen(false);
                  }}
                  to="/huurtaxi"
                  className="flex w-full justify-between px-4 py-3"
                >
                  <div className="flex items-center space-x-2">
                    <p>Voor taxichauffeurs</p>
                    <SmallNewLabel />
                  </div>

                  <img
                    src={ArrowRight}
                    alt="Versturen"
                    className="text-black md:hidden"
                  />
                </Link>

                <div className="my-1 h-[1px] w-[90%] self-center bg-card"></div>

                <Link
                  to="/veelgestelde-vragen"
                  className="flex w-full justify-between px-4 py-3"
                >
                  <p className="font-DMSans">FAQ</p>
                  <img
                    src={ArrowRight}
                    alt="Versturen"
                    className="text-black md:hidden"
                  />
                </Link>

                <div className="my-1 h-[1px] w-[90%] self-center bg-card"></div>

                <div className="my-3 w-full px-4 text-[#494949]">
                  <p className="mb-3 font-DMSans leading-[28px] text-[#494949]">
                    Contact us
                  </p>
                  <div className="mb-4 flex w-full justify-between">
                    <p className="text-sm leading-[22px]">Bellen</p>
                    <a
                      href="tel:+31294300003"
                      className="text-sm text-[#757474]"
                    >
                      +312 94 30 00 03
                    </a>
                  </div>

                  <div className=" flex w-full justify-between">
                    <p className="text-sm leading-[22px]">E-mail</p>
                    <a
                      href="mailto:info@vloto.nl"
                      className="text-sm text-[#757474]"
                    >
                      info@vloto.nl
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Transition>
      </nav>
      {showSectionHeader && (
        <CarSectionHeader
          reviewRef={reviewRef}
          headerBackgroundColor={headerBackgroundColor}
          homeRef={homeRef}
          benefitsRef={benefitsRef}
          ourCarsRef={ourCarsRef}
          ourAppRef={ourAppRef}
          priceRef={priceRef}
          communitiesRef={communitiesRef}
          howItWorksRef={howItWorksRef}
          currentlyInView={currentlyInView}
          showHeaderSectionBorder={showHeaderSectionBorder}
        />
      )}
    </div>
  );
};
