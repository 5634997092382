import { createContext, useState } from "react";
import { Route, Routes } from "react-router-dom";
import HomePage from "./HomePage";
import AppPage from "./pages/App/AppPage";
import { BoatPage } from "./pages/Boats/BoatPage";
import EmailVerification from "./pages/EmailVerification/EmailVerification";
import FaqPage from "./pages/FaqPage/FaqPage";
import PrivacyPage from "./pages/PrivacyPage/PrivacyPage";
import TaxiAppPage from "./pages/TaxiPage/TaxiAppPage";
import { TaxiPage } from "./pages/TaxiPage/TaxiPage";
import TermsPage from "./pages/TermsPage/TermsPage";
import { areas, vehicleTypes } from "./utils/areas";

export const UserContext = createContext(null);

export const App = () => {
  const [selectedCity, setSelectedCity] = useState(areas[0]);
  const [selectedVehicleType, setSelectedVehicleType] = useState(
    vehicleTypes[0]
  );

  return (
    <UserContext.Provider
      value={{
        selectedCity: selectedCity,
        setSelectedCity: setSelectedCity,
        selectedVehicleType: selectedVehicleType,
        setSelectedVehicleType: setSelectedVehicleType,
      }}
    >
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/krijgsman" element={<HomePage city={"De Krijgsman"} />} />
        <Route path="/ijburg" element={<HomePage city={"IJburg"} />} />
        <Route
          path="/weespersluis"
          element={<HomePage city={"Weespersluis"} />}
        />
        <Route
          path="/muiden-vesting"
          element={<HomePage city={"Muiden Vesting"} />}
        />
        <Route
          path="/zeeburgereiland"
          element={<HomePage city={"Zeeburgereiland"} />}
        />
        <Route
          path="/elements"
          element={<HomePage city={"Haarlem Elements"} />}
        />
        <Route path="/houthavens" element={<HomePage city={"Houthavens"} />} />

        <Route path="/driemond" element={<HomePage city={"Driemond"} />} />

        <Route
          path="/loenen-aan-de-vecht"
          element={<HomePage city={"Loenen a/d Vecht"} />}
        />
        <Route path="/privacy-policy" element={<PrivacyPage />} />
        <Route path="/privacy-policy-vloto" element={<PrivacyPage />} />
        <Route path="/algemene-voorwaarden" element={<TermsPage />} />
        <Route path="/app" element={<AppPage />} />

        <Route path="/app/verifieer" element={<EmailVerification />} />

        <Route path="/veelgestelde-vragen" element={<FaqPage />} />

        <Route path={"/boten"} element={<BoatPage />} />
        <Route path={"/boten/ijburg"} element={<BoatPage />} />

        <Route path={"/huurtaxi"} element={<TaxiPage />} />
        <Route path="/huurtaxi/app" element={<TaxiAppPage />} />
      </Routes>
    </UserContext.Provider>
  );
};
