import { forwardRef, useState } from "react";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import FaqItem from "../components/FaqItem";
import { faqs } from "../utils/faqs";

export const BoatsFaqBlock = forwardRef(({ type = "car" }, ref) => {
  const [faqItems, setFaqItems] = useState(faqs);

  const selectFaq = (index) => {
    if (faqItems[index].open) {
      let tempFaqs = faqItems.map((tempFaq) => {
        return { ...tempFaq, open: false };
      });
      setFaqItems(tempFaqs);
      return;
    }

    let newFaqs = faqs.map((faqItem) => {
      return { ...faqItem, open: false };
    });

    newFaqs[index].open = !newFaqs[index].open;

    setFaqItems(newFaqs);
  };

  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full flex-col  self-center py-[80px] md:max-w-[744px] lg:mb-[120px] lg:w-full lg:py-[110px]">
        <div className="self-center">
          <h5
            ref={ref}
            className="mb-10 scroll-mt-[300px] lg:mb-[60px] lg:scroll-mt-[80px] lg:text-32 lg:leading-[40px]"
          >
            Alles wat je wilt weten
          </h5>

          <div className="flex  flex-col items-center space-y-4 px-5 md:space-y-5 md:px-10">
            {faqItems
              .slice(type === "boat" ? 0 : 0, type === "boat" ? 3 : 4)
              .map((faqItem, index) => (
                <FaqItem
                  bgColor={"white"}
                  key={faqItem.id}
                  onClick={() => selectFaq(index)}
                  faq={faqItem}
                />
              ))}

            <Link
              to={"/veelgestelde-vragen"}
              className={"flex w-full md:justify-center"}
            >
              <div
                className="flex w-full items-center rounded-2xl border-[1px] border-card bg-white
          p-5 hover:cursor-pointer md:h-[100px] md:w-[664px] lg:w-[900px] "
              >
                <div className="flex w-full items-center justify-center">
                  <p className="text-lg font-bold leading-[32px] tracking-[-0.5px] text-vlotoGreen md:text-2xl ">
                    Meer veelgestelde vragen
                  </p>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
});
