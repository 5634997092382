import React, { forwardRef } from "react";
import { InView } from "react-intersection-observer";
import MapboxPlaceholder from "../../assets/boats/Illustrations/mapbox-placeholder.png";
import useWindowDimensions from "../../hooks/useWindowDimensions";

export const BoatsLocation = forwardRef(
  ({ setShowAppModal, setCurrentlyInView }, ref) => {
    const { width } = useWindowDimensions("");
    return (
      <InView
        className="flex flex-col justify-center  px-5 py-[80px]  lg:items-center lg:py-[110px]"
        ref={ref}
        as="div"
        threshold={width < 744 ? 0.4 : 0.7}
        onChange={(inView) => inView && setCurrentlyInView("Locatie")}
      >
        <div className="self-center lg:w-[1440px] lg:max-w-[1440px]">
          <div className="flex w-full flex-col items-center justify-center self-center  md:max-w-[744px] md:px-[118px] lg:flex lg:w-full lg:max-w-full lg:flex-row-reverse lg:items-center lg:space-x-[150px] lg:space-x-reverse lg:self-center lg:px-[180px]">
            <img
              src={MapboxPlaceholder}
              alt="Mapbox placeholder"
              className="mb-5 w-full md:mb-10 md:w-[510px] lg:h-[480px] lg:w-[684px]"
            />
            <div className="flex-start flex w-full flex-col  md:max-w-[744px]">
              <h4 className="mb-3 w-[80%] font-DMSans text-2xl font-bold tracking-[-0.5px] md:mb-6 md:w-[442px] md:text-32 md:leading-[40px] lg:w-[242px]">
                Vind onze sloepen hier
              </h4>

              <p className="mb-3 w-[340px] text-[#494949] md:mb-5 lg:w-[243px]">
                Momenteel zijn de boten alleen beschikbaar in Muiden. We zijn
                druk met uitbreiden, dus hou de website in de gaten!
              </p>

              <p className=" mb-8  text-[#494949] md:mb-[50px]">
                <span className="font-bold">Muiden:</span> De Stelling 22
              </p>
              {/* <p className="mb-8 text-[#494949] md:mb-[50px]">
                <span className="font-bold">IJburg:</span> IJburg 66
              </p> */}

              <button
                className="h-[48px] w-[166px] rounded-[8px] bg-vlotoGreen font-semibold text-white"
                onClick={setShowAppModal}
              >
                Download de app
              </button>
            </div>
          </div>
        </div>
      </InView>
    );
  }
);
